import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import com.cmp.portfolio.sp

data class CustomTypography(
    val mini: TextStyle,
    val miniBold: TextStyle,
    val miniSemiBold: TextStyle,
    val small: TextStyle,
    val smallBold: TextStyle,
    val smallSemiBold: TextStyle,
    val regular: TextStyle,
    val regularBold: TextStyle,
    val large: TextStyle,
)

@Composable
fun customFontStyles(): CustomTypography {
    return CustomTypography(
        mini = TextStyle(
            color = Color.Black,
            fontSize = 12.sp
        ),
        miniBold = TextStyle(
            color = Color.Black,
            fontWeight = FontWeight.Bold,
            fontSize = 12.sp
        ),
        miniSemiBold = TextStyle(
            color = Color.Black,
            fontWeight = FontWeight.SemiBold,
            fontSize = 12.sp
        ),
        small = TextStyle(
            color = Color.Black,
            fontSize = 16.sp
        ),
        smallBold = TextStyle(
            color = Color.Black,
            fontWeight = FontWeight.Bold,
            fontSize = 16.sp
        ),
        smallSemiBold = TextStyle(
            color = Color.Black,
            fontWeight = FontWeight.SemiBold,
            fontSize = 16.sp
        ),
        regular = TextStyle(
            color = Color.Black,
            fontSize = 18.sp,
            fontWeight = FontWeight.Normal
        ),
        regularBold = TextStyle(
            color = Color.Black,
            fontSize = 18.sp,
            fontWeight = FontWeight.Bold
        ),
        large = TextStyle(
            color = Color.Black,
            fontSize = 24.sp,
            lineHeight = 20.sp,
            letterSpacing = 1.sp,
            fontWeight = FontWeight.Bold
        )
    )
}

val LocalCustomTypography = staticCompositionLocalOf {
    CustomTypography(
        mini = TextStyle.Default,
        miniBold = TextStyle.Default,
        miniSemiBold = TextStyle.Default,
        small = TextStyle.Default,
        smallBold = TextStyle.Default,
        smallSemiBold = TextStyle.Default,
        regular = TextStyle.Default,
        regularBold = TextStyle.Default,
        large = TextStyle.Default
    )
}

@Composable
fun CustomTheme(content: @Composable () -> Unit) {
    val customTypography = customFontStyles()
    CompositionLocalProvider(LocalCustomTypography provides customTypography) {
        content()
    }
}