package com.cmp.portfolio

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.Dp
import com.cmp.portfolio.mobile.AboutScreen
import com.cmp.portfolio.mobile.AutomotiveProjectScreen
import com.cmp.portfolio.mobile.ConnectScreen
import com.cmp.portfolio.mobile.ExperienceScreen
import com.cmp.portfolio.mobile.ProjectScreen
import com.cmp.portfolio.mobile.TechStackScreen
import com.cmp.portfolio.mobile.TitleCard
import com.cmp.portfolio.mobile.TopBar
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_automotive_project
import composemultiplatform.composeapp.generated.resources.ic_experience
import composemultiplatform.composeapp.generated.resources.ic_projects_2
import composemultiplatform.composeapp.generated.resources.ic_tech_stack

@Composable
fun MobileScreen(
    maxWidth: Dp,
    maxHeight: Dp,
    onResumeDownloadClicked: () -> Unit,
    onSendEmailClicked: () -> Unit
) {
    val snackbarHostState = remember { SnackbarHostState() }
    val listState = rememberLazyListState()
    val backgroundColor = Color(0xFFF2F2EA)
    Scaffold(
        topBar = {
        },
        snackbarHost = {
            SnackbarHost(
                hostState = snackbarHostState,
                snackbar = { snackBarData ->
                    Snackbar(
                        snackbarData = snackBarData,
                        contentColor = Color.White,
                        actionColor = Color.Yellow
                    )
                }
            )
        },
        content = { padding ->
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(padding)
                    .background(
                        backgroundColor
                    ),
                state = listState
            )
            {
                item(key = "top bar")
                {
                    TopBar(onResumeDownloadClicked, backgroundColor)
                }
                item(key = "about_section") {
                    AboutScreen(
                        onSendEmailClicked = onSendEmailClicked
                    )
                }
                item(key = "tech_stack_title") {
                    TitleCard(title = "Tech Stack", resourceId = Res.drawable.ic_tech_stack)
                }
                item(key = "skills_section") {
                    TechStackScreen()
                }
                item(key = "project_title") {
                    TitleCard(title = "Projects", resourceId = Res.drawable.ic_projects_2)
                }
                item(key = "projects_section") {
                    ProjectScreen()
                }
                item(key = "automotive_experience_title") {
                    TitleCard(
                        title = "Automotive Project",
                        resourceId = Res.drawable.ic_automotive_project
                    )
                }
                item {
                    AutomotiveProjectScreen()
                }
                item(key = "experience_title") {
                    TitleCard(title = "Experience", resourceId = Res.drawable.ic_experience)
                }
                item(key = "experience_section") {
                    ExperienceScreen()
                }
                item(key = "connect_section") {
                    val uriHandler = LocalUriHandler.current
                    ConnectScreen(
                        onSendEmailClicked = onSendEmailClicked,
                        onLinkedInClicked = { uriHandler.openUri(PortfolioConstants.LINKEDIN_URL) })
                }
            }
        })
}







