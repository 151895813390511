package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.animation.SizeTransform
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.animation.with
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarDuration
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.SECTION
import com.cmp.portfolio.note
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_download
import composemultiplatform.composeapp.generated.resources.ic_tab_dot
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalAnimationApi::class)
@Composable
fun NewDesktopScreen(onResumeDownloadClicked: () -> Unit, onSendEmailClicked: () -> Unit) {
    val tabBarList = listOf(
        SECTION.ABOUT,
        SECTION.TECH_STACK,
        SECTION.PROJECTS,
        SECTION.AUTOMOTIVE_PROJECTS,
        SECTION.EXPERIENCE,
        SECTION.CONTACT,
    )
    var currentTab by remember { mutableStateOf(SECTION.ABOUT) }
    val snackBarHostState = remember { SnackbarHostState() }

    val backgroundColor = Color(0xFFF2F2EA)
    Scaffold(snackbarHost = {
        SnackbarHost(
            hostState = snackBarHostState,
            snackbar = { snackBarData ->
                Snackbar(
                    snackbarData = snackBarData,
                    contentColor = Color.White,
                    actionColor = Color.Yellow
                )
            }
        )
    }) {
        LaunchedEffect("First Time Note")
        {
            snackBarHostState.showSnackbar(
                message = note,
                duration = SnackbarDuration.Short
            )
        }
        Box(
            modifier = Modifier.fillMaxSize().background(backgroundColor),
            contentAlignment = Alignment.Center
        )
        {
            Column(modifier = Modifier.fillMaxSize()) {
//            NewDesktopTopBar(backgroundColor = backgroundColor, onResumeDownloadClicked = {})
                Row(
                    modifier = Modifier.fillMaxWidth().fillMaxHeight()
                        .padding(10.dp)
                ) {
                    Box(modifier = Modifier.fillMaxHeight().weight(0.25f))
                    {
                        FixedLeftBar(onClickAction = {
                            currentTab = it
                        })
                    }
                    Box(modifier = Modifier.fillMaxHeight().weight(0.7f))
                    {
                        Column(modifier = Modifier.fillMaxSize()) {
                            Row(
                                modifier = Modifier.fillMaxWidth().padding(end = 20.dp),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                LazyRow(
                                    Modifier.clip(shape = RoundedCornerShape(50.dp))
                                        .background(Color(0xFF0F0F0F)),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.spacedBy(10.dp)
                                ) {
                                    items(tabBarList)
                                    {
                                        TabItem(
                                            text = it.displayName,
                                            isSelected = it == currentTab,
                                            onItemClicked = {
                                                currentTab = it
                                            })
                                    }
                                }
                                Card(
                                    modifier = Modifier
                                        .border(
                                            color = Color(0xFFFBFBF8),
                                            shape = RoundedCornerShape(40.dp),
                                            width = 2.dp
                                        )
                                        .clickable(interactionSource = remember {
                                            MutableInteractionSource()
                                        }, indication = null) {
                                            onResumeDownloadClicked.invoke()
                                        },
                                    shape = RoundedCornerShape(40.dp),
                                    elevation = CardDefaults.cardElevation(10.dp),
                                    colors = CardColors(
                                        containerColor = backgroundColor,
                                        contentColor = Color.Black,
                                        Color.Transparent,
                                        Color.Transparent
                                    )

                                ) {
                                    Row(
                                        modifier = Modifier.padding(
                                            horizontal = 15.dp,
                                            vertical = 10.dp
                                        ),
                                        horizontalArrangement = Arrangement.spacedBy(5.dp),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Image(
                                            modifier = Modifier.size(24.dp),
                                            painter = painterResource(Res.drawable.ic_download),
                                            contentDescription = "Menu Icon"
                                        )
                                        Text(
                                            text = "Resume",
                                            style = LocalCustomTypography.current.small
                                        )
                                    }
                                }
                            }

                            Card(
                                modifier = Modifier.fillMaxSize().padding(top = 10.dp, end = 10.dp),
                                colors = CardColors(
                                    Color.White,
                                    Color.Black,
                                    Color.Transparent,
                                    Color.Transparent
                                )
                            ) {
                                AnimatedContent(
                                    targetState = currentTab,
                                    transitionSpec = {
                                        if (targetState > initialState) {
                                            slideInHorizontally { width -> width } + fadeIn() with
                                                    slideOutHorizontally { width -> -width } + fadeOut()
                                        } else {
                                            slideInHorizontally { width -> -width } + fadeIn() with
                                                    slideOutHorizontally { width -> width } + fadeOut()
                                        }.using(
                                            SizeTransform(clip = false)
                                        )
                                    }
                                )
                                {
                                    when (it) {
                                        SECTION.ABOUT -> AboutMeScreen()
                                        SECTION.TECH_STACK -> NewDeskTopTechStackScreen()
                                        SECTION.PROJECTS -> NewDesktopProjectPreviewScreen()
                                        SECTION.AUTOMOTIVE_PROJECTS -> NewDesktopAutomotiveProjectScreen()
                                        SECTION.EXPERIENCE -> NewDesktopExperienceScreen()
                                        SECTION.CONTACT -> NewDesktopContactMeScreen(
                                            onSendEmailClicked
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


@Composable
fun TabItem(text: String, isSelected: Boolean, onItemClicked: () -> Unit) {
    Box(
        modifier = Modifier.padding(vertical = 2.dp, horizontal = 2.dp)
            .clip(shape = RoundedCornerShape(50.dp))
            .background(if (isSelected) Color.White else Color.Transparent).clickable {
                onItemClicked()
            },
        contentAlignment = Alignment.Center
    )
    {
        Row(
            Modifier.padding(
                vertical = 10.dp,
                horizontal = 20.dp
            ),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier.size(8.dp),
                painter = painterResource(Res.drawable.ic_tab_dot),
                contentDescription = "Dot Icon"
            )
            Text(
                text = text, color = if (isSelected) Color.Black else Color.White
            )
        }

    }
}
