package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.animateIntAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.PortfolioConstants
import com.cmp.portfolio.SECTION
import com.cmp.portfolio.copyRights
import com.cmp.portfolio.shortNote
import com.cmp.portfolio.sp
import com.cmp.portfolio.subtitle
import com.cmp.portfolio.titleAndroidDev
import com.cmp.portfolio.titleInfotainmentDev
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_contact_linkedin
import composemultiplatform.composeapp.generated.resources.ic_contact_mail
import composemultiplatform.composeapp.generated.resources.new_profile_picture_1
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun FixedLeftBar(onClickAction: (SECTION) -> Unit) {
    val imageBackgroundColor = Color(0xFFDBDDD8)
    val highlightColor = Color(0xFFE7FE55)
    val uriHandler = LocalUriHandler.current
    Column(
        Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Card(
            modifier = Modifier
                .fillMaxWidth().weight(0.9f)
                .padding(horizontal = 10.dp),
            shape = RoundedCornerShape(20.dp),
            colors = CardColors(
                containerColor = imageBackgroundColor,
                contentColor = Color.Transparent,
                Color.Transparent,
                Color.Transparent
            )
        ) {
            Row(
                modifier = Modifier
                    .weight(0.3f)
                    .fillMaxWidth()
                    .padding(top = 20.dp, start = 20.dp, end = 20.dp)
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .weight(0.5f)
                ) {
                    Text(
                        text = "Hello, I'm",
                        style = LocalCustomTypography.current.regular
                    )
                    Text(
                        text = "Kishore\nKumarasamy",
                        style = LocalCustomTypography.current.large
                    )
                }
                Image(
                    modifier = Modifier
                        .fillMaxHeight()
                        .weight(0.6f),
                    painter = painterResource(Res.drawable.new_profile_picture_1),
                    contentDescription = "Profile Image"
                )
            }
            Card(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(0.7f)
                    .padding(start = 2.dp, end = 2.dp, bottom = 2.dp),
                shape = RoundedCornerShape(20.dp),
                colors = CardColors(
                    containerColor = Color(0xFFE8E8DE),
                    contentColor = Color.Transparent,
                    Color.Transparent,
                    Color.Transparent
                )
            ) {
                Column(modifier = Modifier.fillMaxWidth()) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth().weight(0.2f)
                            .padding(start = 10.dp, end = 10.dp, top = 10.dp),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        Column(modifier = Modifier.weight(0.7f)) {
                            Text(
                                text = titleAndroidDev,
                                style = LocalCustomTypography.current.smallBold
                            )
                            Text(
                                modifier = Modifier,
                                text = titleInfotainmentDev,
                                color = Color(0XFFA4A59D),
                                style = LocalCustomTypography.current.small
                            )
                        }
                        Row(
                            modifier = Modifier.weight(0.3f),
                            horizontalArrangement = Arrangement.spacedBy(5.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Box(
                                modifier = Modifier
                                    .clip(CircleShape)
                                    .background(Color.White)
                                    .clickable {

                                    },
                                contentAlignment = Alignment.Center
                            )
                            {
                                Image(
                                    modifier = Modifier
                                        .size(45.dp)
                                        .padding(10.dp),
                                    painter = painterResource(Res.drawable.ic_contact_mail),
                                    contentDescription = "Email Icon"
                                )
                            }
                            Box(
                                modifier = Modifier
                                    .clip(CircleShape)
                                    .background(Color(0XFF0077B5))
                                    .clickable {
                                        uriHandler.openUri(PortfolioConstants.LINKEDIN_URL)
                                    },
                                contentAlignment = Alignment.Center
                            )
                            {
                                Image(
                                    modifier = Modifier
                                        .size(45.dp)
                                        .padding(10.dp),
                                    painter = painterResource(Res.drawable.ic_contact_linkedin),
                                    contentDescription = "Linked In Icon"
                                )
                            }
                        }
                    }
                    Card(
                        modifier = Modifier
                            .fillMaxWidth().weight(0.2f)
                            .padding(10.dp),
                        colors = CardColors(
                            containerColor = Color.White,
                            contentColor = Color.Black,
                            Color.Transparent,
                            Color.Transparent,
                        )
                    ) {
                        Box(
                            modifier = Modifier
                                .fillMaxSize()
                                .padding(10.dp),
                            contentAlignment = Alignment.Center
                        )
                        {
                            Text(
                                text = subtitle,
                                style = LocalCustomTypography.current.small
                            )
                        }
                    }
                    highlightView(Modifier.fillMaxWidth().weight(0.6f), onClickAction)
                    Text(
                        modifier = Modifier.fillMaxWidth().weight(0.1f)
                            .padding(horizontal = 5.dp, vertical = 10.dp),
                        textAlign = TextAlign.Center,
                        text = copyRights,
                        style = LocalCustomTypography.current.small,
                        color = Color(0XFFA4A59D),
                    )
                }
            }
        }
        Card(
            modifier = Modifier.fillMaxWidth().weight(0.1f)
                .padding(horizontal = 10.dp),
            colors = CardColors(
                containerColor = Color(0xFF253238),
                contentColor = Color.White,
                Color.Transparent,
                Color.Transparent,
            )
        ) {
            Box(
                Modifier.fillMaxSize().padding(horizontal = 10.dp),
                contentAlignment = Alignment.Center
            )
            {
                Text(
                    text = shortNote,
                    color = Color.White,
                    style = LocalCustomTypography.current.small,
                )
            }
        }
    }
}

@Composable
fun highlightView(modifier: Modifier, onClickAction: (SECTION) -> Unit) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        val newColor = Color(0xFF253238)
        Row(
            modifier = Modifier.fillMaxWidth().weight(0.5f)
                .padding(horizontal = 2.dp),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            highlightNumberCard(
                modifier = Modifier.fillMaxHeight().weight(0.5f),
                title = "Years Experience",
                supportText = "+",
                value = 3,
                onClickAction = {
                    onClickAction(SECTION.EXPERIENCE)
                },
                cardColors = CardColors(
                    containerColor = newColor,
                    contentColor = Color.White,
                    disabledContentColor = Color.Transparent,
                    disabledContainerColor = Color.Transparent
                )
            )
            highlightNumberCard(
                modifier = Modifier.fillMaxHeight().weight(0.5f),
                title = "Handled Projects",
                supportText = "+",
                value = 25,
                onClickAction = {
                    onClickAction(SECTION.PROJECTS)
                },
                cardColors = CardColors(
                    containerColor = newColor,
                    contentColor = Color.White,
                    disabledContentColor = Color.Transparent,
                    disabledContainerColor = Color.Transparent
                )
            )
        }
        Row(
            modifier = Modifier.fillMaxWidth().weight(0.5f)
                .padding(horizontal = 2.dp),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            highlightNumberCard(
                modifier = Modifier.fillMaxHeight().weight(0.5f),
                title = "Companies",
                supportText = "+",
                value = 4,
                onClickAction = {
                    onClickAction(SECTION.EXPERIENCE)
                },
                cardColors = CardColors(
                    containerColor = newColor,
                    contentColor = Color.White,
                    disabledContentColor = Color.Transparent,
                    disabledContainerColor = Color.Transparent
                )
            )
            highlightNumberCard(
                modifier = Modifier.fillMaxHeight().weight(0.5f),
                title = "Downloads",
                supportText = "M+",
                value = 1,
                onClickAction = {
                    onClickAction(SECTION.PROJECTS)
                },
                cardColors = CardColors(
                    containerColor = newColor,
                    contentColor = Color.White,
                    disabledContentColor = Color.Transparent,
                    disabledContainerColor = Color.Transparent
                )
            )
        }
    }
}

@Composable
fun highlightNumberCard(
    modifier: Modifier = Modifier,
    title: String,
    supportText: String = "",
    value: Int,
    cardColors: CardColors,
    onClickAction: () -> Unit
) {
    var count by remember { mutableStateOf(0) }
    val animationDuration = when {
        value <= 10 -> 500
        value <= 50 -> 500
        value <= 100 -> 500
        else -> 3000
    }
    val animatedCount by animateIntAsState(
        targetValue = count,
        animationSpec = tween(animationDuration, easing = LinearEasing)
    )
    LaunchedEffect(key1 = value)
    {
        delay(200)
        count = value
    }
    Card(
        modifier = modifier.padding(10.dp),
        elevation = CardDefaults.cardElevation(8.dp),
        shape = RoundedCornerShape(20.dp),
        colors = cardColors
    ) {
        Box(
            modifier = Modifier.fillMaxSize().clickable { onClickAction.invoke() },
            contentAlignment = Alignment.Center
        )
        {
            Column(
                modifier = Modifier.padding(10.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                AnimatedCounter(
                    count = animatedCount, style = LocalCustomTypography.current.regularBold,
                    color = cardColors.contentColor,
                    fontSize = 25.sp,
                    supportText = supportText
                )
                Text(
                    text = title,
                    style = LocalCustomTypography.current.small,
                    color = cardColors.contentColor
                )
            }

        }
    }
}