package com.cmp.portfolio.newDesktop


import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.ClickableText
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.ExperimentalTextApi
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.UrlAnnotation
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_about_me_3
import org.jetbrains.compose.resources.painterResource

@Composable
fun ChatScreen() {
    val chatList = listOf(
        buildStyledText(
            "It all started with a deep-rooted passion for technology and innovation in mobile application development and vehicle infotainment systems.",
            listOf(
                Pair("mobile application development", Type(bold = true)),
                Pair("vehicle infotainment systems", Type(bold = true))
            )
        ),
        buildStyledText(
            "I hold a bachelor's degree in Information Technology with distinction. To further hone my skills, I pursued a master's degree in Engineering at the University of Waterloo, specializing in Software Engineering.",
            listOf(
                Pair(
                    "University of Waterloo",
                    Type(bold = true, url = "https://www.uwaterloo.ca/")
                )
            )
        ),
        buildStyledText(
            "With 3+ years of experience, I have developed 25+ Android applications, including those for Wear OS, and designed adaptive solutions for various mobile and tablet screens. Several of these applications have reached over 1 million users. My work spans both startup and MNC environments, including notable contributions at Zoho Corporation.",
            listOf(
                Pair("3+", Type(bold = true)),
                Pair("25+", Type(bold = true)),
                Pair("1 million", Type(bold = true)),
                Pair("Zoho Corporation", Type(bold = true, url = "https://www.zohocorp.com/"))
            )
        ),
        buildStyledText(
            "I have a proven track record as both a standalone developer and a collaborative team player, including developing HMI automotive infotainment systems for electric vehicles from scratch. I always prioritize simplicity, scalability, and stability in my work.",
            listOf(Pair("HMI automotive infotainment systems", Type(bold = true)))
        ),
        buildStyledText(
            "I am currently working at Freedom, contributing to the development of the Freedom Android application.",
            listOf(Pair("Freedom", Type(url = "https://freedom.to/", bold = true)))
        )
    )
    LazyColumn(verticalArrangement = Arrangement.spacedBy(5.dp)) {
        items(chatList) {
            if (chatList.indexOf(it) == 0)
                firstMessageItem(it)
            else
                messageItem(it)
        }
    }
}

data class Type(
    val bold: Boolean = false,
    val url: String = "",
    val color: Color = Color.Black
)

@OptIn(ExperimentalTextApi::class)
fun buildStyledText(
    sentence: String,
    phrasesToCustomise: List<Pair<String, Type>>
): AnnotatedString {
    return buildAnnotatedString {
        append(sentence)
        phrasesToCustomise.forEach { phrase ->
            val startIndex = sentence.indexOf(phrase.first)
            val endIndex = startIndex + phrase.first.length
            val type = phrase.second
            addStyle(
                style = SpanStyle(
                    fontWeight = if (type.bold) FontWeight.Bold else FontWeight.Normal,
                    color = if (type.url.isEmpty()) type.color else Color(0xFF3F97FF)
                ), start = startIndex, end = endIndex
            )
            if (type.url.isNotEmpty()) {
                addUrlAnnotation(
                    UrlAnnotation(type.url),
                    start = startIndex,
                    end = endIndex
                )
            }
        }
    }
}

@Composable
fun firstMessageItem(text: AnnotatedString) {
    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.spacedBy(5.dp)) {
        Box(
            modifier = Modifier.clip(CircleShape).background(Color(0XFFE5E5E5)),
            contentAlignment = Alignment.Center
        )
        {
            Image(
                modifier = Modifier.size(36.dp).padding(5.dp),
                painter = painterResource(Res.drawable.ic_about_me_3),
                contentDescription = "Chat Profile Icon"
            )
        }
        Box(
            modifier = Modifier.fillMaxWidth().clip(
                RoundedCornerShape(
                    topStart = 0.dp,
                    topEnd = 20.dp,
                    bottomEnd = 20.dp,
                    bottomStart = 20.dp
                )
            ).background(Color(0XFFF7F9FA)),
            contentAlignment = Alignment.Center
        ) {
            Text(
                modifier = Modifier.padding(20.dp),
                text = text,
                style = LocalCustomTypography.current.small
            )
        }
    }
}

@OptIn(ExperimentalTextApi::class)
@Composable
fun messageItem(text: AnnotatedString) {
    Box(
        modifier = Modifier.fillMaxWidth().padding(start = 41.dp).clip(RoundedCornerShape(20.dp))
            .background(Color(0XFFF7F9FA)),
        contentAlignment = Alignment.Center
    ) {
        val uriHandler = LocalUriHandler.current
        ClickableText(
            modifier = Modifier.padding(20.dp),
            text = text,
            style = LocalCustomTypography.current.small,
            onClick = {
                text
                    .getUrlAnnotations(it, it)
                    .firstOrNull()?.let { annotation ->
                        uriHandler.openUri(annotation.item.url)
                    }
            }
        )
    }
}