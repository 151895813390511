package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.automotiveExperienceItems
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_vehicle_info_real_vehicle
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDesktopAutomotiveProjectScreen() {
    Box(modifier = Modifier.fillMaxWidth().padding(10.dp))
    {
        LazyColumn(verticalArrangement = Arrangement.spacedBy(20.dp)) {
            item {
                Box(
                    modifier = Modifier.fillMaxWidth().height(400.dp)
                        .clip(RoundedCornerShape(10.dp))
                        .background(Color.Black)
                )
                {
                    Image(
                        modifier = Modifier
                            .fillMaxWidth(),
                        painter = painterResource(Res.drawable.ic_vehicle_info_real_vehicle),
                        contentDescription = "Automotive Project Preview"
                    )
                }
            }
            items(automotiveExperienceItems)
            {
                AutomotiveItem(
                    it.title,
                    it.description
                )
            }
        }
    }
}

@Composable
fun AutomotiveItem(title: String, description: String) {
    Column(verticalArrangement = Arrangement.spacedBy(5.dp)) {
        Text(
            title,
            style = LocalCustomTypography.current.smallBold
        )
        Text(
            description,
            style = LocalCustomTypography.current.small
        )
    }
}