package com.cmp.portfolio.mobile

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.automotiveExperienceItems
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_vehicle_info_real_vehicle
import org.jetbrains.compose.resources.painterResource

@Composable
fun AutomotiveProjectScreen() {
    var isAutomotiveProjectExpanded by remember { mutableStateOf(false) }
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 10.dp),
        shape = RoundedCornerShape(
            topStart = 0.dp,
            topEnd = 10.dp,
            bottomStart = 10.dp,
            bottomEnd = 10.dp
        ),
        colors = CardColors(
            containerColor = Color.White,
            contentColor = Color.Black,
            Color.Transparent,
            Color.Transparent
        )
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(10.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(10.dp)
        ) {
            Image(
                modifier = Modifier
                    .height(200.dp)
                    .fillMaxWidth().clip(RoundedCornerShape(10.dp)),
                painter = painterResource(Res.drawable.ic_vehicle_info_real_vehicle),
                contentDescription = "Automotive Project Preview"
            )
            Column(verticalArrangement = Arrangement.spacedBy(20.dp)) {
                (if (isAutomotiveProjectExpanded) automotiveExperienceItems else automotiveExperienceItems.subList(
                    0,
                    1
                )).forEach { automotive ->
                    AutomotiveItem(automotive.title, automotive.description)
                }
                if (!isAutomotiveProjectExpanded) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(10.dp)
                            .clickable(
                                interactionSource = MutableInteractionSource(),
                                indication = null
                            ) {
                                isAutomotiveProjectExpanded = true
                            }, contentAlignment = Alignment.Center
                    )
                    {
                        Row(
                            horizontalArrangement = Arrangement.spacedBy(5.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(Icons.Filled.ArrowDropDown, contentDescription = "Load More Icon")
                            Text(
                                text = "Read More",
                                fontWeight = FontWeight.Bold,
                                color = Color.Black
                            )
                        }

                    }
                }
            }
        }
    }
}

@Composable
fun AutomotiveItem(title: String, description: String) {
    Column(verticalArrangement = Arrangement.spacedBy(5.dp)) {
        Text(
            title,
            style = LocalCustomTypography.current.smallBold
        )
        Text(
            description,
            style = LocalCustomTypography.current.small
        )
    }
}