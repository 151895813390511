package com.cmp.portfolio

import CustomTheme
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.cmp.portfolio.newDesktop.NewDesktopScreen
import dev.gitlive.firebase.analytics.FirebaseAnalytics

@Composable
fun App(
    userAgent: String,
    currentDeviceType: DeviceType,
    firebaseAnalytics: FirebaseAnalytics,
    onResumeDownloadClicked: () -> Unit,
    onSendEmailClicked: () -> Unit
) {

    CustomTheme {
        HomeScreen(
            userAgent,
            currentDeviceType,
            firebaseAnalytics,
            onResumeDownloadClicked,
            onSendEmailClicked
        )
    }
}

@Composable
private fun HomeScreen(
    userAgent: String,
    currentDeviceType: DeviceType,
    firebaseAnalytics: FirebaseAnalytics,
    onResumeDownloadClicked: () -> Unit,
    onSendEmailClicked: () -> Unit
) {
    BoxWithConstraints(modifier = Modifier.fillMaxSize())
    {
        when (currentDeviceType) {
            DeviceType.MOBILE -> {
                firebaseAnalytics.logEvent("device_type", mapOf("type" to "mobile"))
                MobileScreen(
                    maxWidth,
                    maxWidth,
                    onResumeDownloadClicked = {
                        firebaseAnalytics.logEvent(
                            "button_click",
                            mapOf("name" to "mobile_resume_download")
                        )
                        onResumeDownloadClicked()
                    },
                    onSendEmailClicked = onSendEmailClicked
                )
            }

            DeviceType.TABLET -> {
                firebaseAnalytics.logEvent("device_type", mapOf("type" to "tablet"))
                MobileScreen(
                    maxWidth,
                    maxWidth,
                    onResumeDownloadClicked = {
                        firebaseAnalytics.logEvent(
                            "button_click",
                            mapOf("name" to "tablet_resume_download")
                        )
                        onResumeDownloadClicked()
                    },
                    onSendEmailClicked = onSendEmailClicked
                )
            }

            DeviceType.DESKTOP -> {
                firebaseAnalytics.logEvent("device_type", mapOf("type" to "desktop"))
                NewDesktopScreen(onResumeDownloadClicked, onSendEmailClicked)
            }
        }
    }
}
