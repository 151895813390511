package com.cmp.portfolio

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.initialize
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLAnchorElement


/**
 * USE FOR INDIVIDUAL MOBILE AND DESKTOP VIEW
 * <meta charset="UTF-8">
 * <meta name="viewport" content="width=device-width, initial-scale=1.0">
 */
@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    Firebase.initialize(
        options = FirebaseOptions(
            applicationId = "1:758074762551:web:3117d7d86c6407bf6866b2",
            apiKey = "AIzaSyD3V_rAYACq3U1I6zGSgjEHbYX6p1fl7T0",
            projectId = "portfolio-49cc3"
        )
    )
    val firebaseAnalytics = Firebase.analytics
    onWasmReady {
        CanvasBasedWindow(canvasElementId = "ComposeTarget") {
            App(
                userAgent = window.navigator.userAgent,
                currentDeviceType = getDeviceType(),
                firebaseAnalytics = firebaseAnalytics,
                onResumeDownloadClicked = {
                    downloadPdf()
                },
                onSendEmailClicked = {
                    sendEmail()
                })
        }
    }
}

fun getDeviceType(): DeviceType {
    val ua = window.navigator.userAgent
    println("USER AGENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>$ua")
    return when {
        isIOS() -> DeviceType.TABLET // Assuming all iOS devices are tablets for simplicity
        isTablet() -> DeviceType.TABLET
        isMobile() -> DeviceType.MOBILE
        else -> DeviceType.DESKTOP
    }
}

private fun isIOS(): Boolean {
    val platform = window.navigator.platform
    val userAgent = window.navigator.userAgent

    return listOf(
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
    ).contains(platform) || (userAgent.contains("Mac") && js("'ontouchend' in document") as Boolean)
}

private fun isTablet(): Boolean {
    val ua = window.navigator.userAgent.lowercase()
    return Regex("(tablet|ipad|playbook|silk)|(android(?!.*mobile))").containsMatchIn(ua)
}

private fun isMobile(): Boolean {
    val ua = window.navigator.userAgent.lowercase()
    return Regex("mobile|ip(hone|od)|android|blackberry|iemobile|kindle|silk-accelerated|(hpw|web)os|opera m(obi|ini)").containsMatchIn(
        ua
    )
}

fun sendEmail() {
    val email = "kishore.rathika5304@gmail.com"
    val subject = "Interest in Discussing Potential Opportunities"
    val body =
        "Hello Kishore,\n" +
                "\n" +
                "I hope you’re doing well. I recently visited your portfolio website and would like to get in touch. I’d appreciate the opportunity to connect and discuss this further at a time that works for you.\n" +
                "\n" +
                "Thank you for your time and consideration."
    val encodedSubject = encodeURIComponent(subject)
    val encodedBody = encodeURIComponent(body)

    val mailtoLink = "mailto:$email?subject=$encodedSubject&body=$encodedBody"
    val link = document.createElement("a") as HTMLAnchorElement
    link.href = mailtoLink
    link.click()
}

fun encodeURIComponent(value: String): String {
    return js("encodeURIComponent(value)").unsafeCast<String>()
}

fun downloadPdf() {
    val link = document.createElement("a") as HTMLAnchorElement
    link.href = "KISHORE_KUMARASAMY_CV.pdf"
    link.download = "KISHORE_KUMARASAMY_CV.pdf"
    link.click()
}

