package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.PortfolioConstants.LINKEDIN_URL
import com.cmp.portfolio.contactMeDescription
import com.cmp.portfolio.contactMeLocation
import com.cmp.portfolio.sp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_contact_linkedin
import composemultiplatform.composeapp.generated.resources.ic_contact_location
import composemultiplatform.composeapp.generated.resources.ic_contact_location_maps
import composemultiplatform.composeapp.generated.resources.ic_contact_mail
import composemultiplatform.composeapp.generated.resources.ic_contact_sitting_avatar
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDesktopContactMeScreen(onSendEmailClicked: () -> Unit) {
    val uriHandler = LocalUriHandler.current
    Column(
        modifier = Modifier.fillMaxSize().padding(10.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {
        Row(
            modifier = Modifier.fillMaxWidth().weight(0.5f).clip(
                RoundedCornerShape(10.dp)
            ).background(Color(0xFFE8E8DE)),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().weight(0.6f)
            ) {
                Column(
                    modifier = Modifier.padding(20.dp),
                    verticalArrangement = Arrangement.spacedBy(20.dp)
                ) {
                    Text(
                        text = "Let's connect",
                        fontSize = 40.sp,
                        fontWeight = FontWeight.Bold,
                        color = Color.Black
                    )
                    Text(
                        text = contactMeDescription,
                        style = LocalCustomTypography.current.regular
                    )
                }
                Column(
                    modifier = Modifier.padding(20.dp),
                    verticalArrangement = Arrangement.spacedBy(10.dp)
                ) {
                    ContactItem(
                        text = contactMeLocation,
                        iconResId = Res.drawable.ic_contact_location,
                        onItemClicked = {}
                    )
                    ContactItem(
                        text = "kishore.rathika5304@gmail.com",
                        iconResId = Res.drawable.ic_contact_mail,
                        onSendEmailClicked
                    )
                    ContactItem(
                        "kishore-kumarasamy", iconResId = Res.drawable.ic_contact_linkedin,
                        onItemClicked = {
                            uriHandler.openUri(LINKEDIN_URL)
                        }
                    )
                }
            }
            Image(
                modifier = Modifier.fillMaxHeight().weight(0.4f),
                painter = painterResource(Res.drawable.ic_contact_sitting_avatar),
                contentDescription = "Avatar Sitting Image"
            )
        }
        Image(
            modifier = Modifier.fillMaxWidth().weight(0.5f).clip(RoundedCornerShape(10.dp)),
            painter = painterResource(Res.drawable.ic_contact_location_maps),
            contentScale = ContentScale.Crop,
            contentDescription = "Maps Image"
        )
    }

}


//
//
//Column(
//modifier = Modifier.fillMaxWidth().padding(10.dp),
//horizontalAlignment = Alignment.CenterHorizontally,
//verticalArrangement = Arrangement.spacedBy(50.dp)
//)
//{
//    Column {
//        Text("Get in touch", style = LocalCustomTypography.current.large)
//        Text("Let's Build Greatest Apps Together", style = LocalCustomTypography.current.regular)
//    }
//    Row {
//        Card(
//            colors = CardColors(
//                Color(0XFFF14D42),
//                Color.Black,
//                Color.Transparent,
//                Color.Transparent
//            ),
//            elevation = CardDefaults.cardElevation(10.dp),
//            shape = RoundedCornerShape(20.dp)
//        ) {
//            Row(
//                modifier = Modifier.padding(10.dp),
//                verticalAlignment = Alignment.CenterVertically,
//                horizontalArrangement = Arrangement.SpaceBetween
//            ) {
//                Column {
//                    Text(
//                        "GET IN TOUCH",
//                        color = Color.White,
//                        style = LocalCustomTypography.current.large,
//                        fontWeight = FontWeight.Bold
//                    )
//                    Text(
//                        "Let's Build Greatest Apps Together",
//                        color = Color.White,
//                        style = LocalCustomTypography.current.regular,
//                        fontWeight = FontWeight.Bold
//                    )
//                }
//                Icon(
//                    modifier = Modifier.size(72.dp),
//                    imageVector = Icons.Default.Person,
//                    contentDescription = "Person Icon",
//                    tint = Color(0xFFDC1B21)
//                )
//            }
//            Column(
//                modifier = Modifier.padding(10.dp).clip(RoundedCornerShape(20.dp))
//                    .background(Color.White),
//                horizontalAlignment = Alignment.CenterHorizontally,
//                verticalArrangement = Arrangement.spacedBy(20.dp)
//            )
//            {
//                Row(
//                    modifier = Modifier.padding(top = 50.dp),
//                    horizontalArrangement = Arrangement.spacedBy(30.dp),
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Image(
//                        modifier = Modifier.size(24.dp),
//                        painter = painterResource(Res.drawable.ic_contact_mail_1),
//                        contentDescription = "Email Icon"
//                    )
//                    Column {
//                        Text(
//                            "Email",
//                            style = LocalCustomTypography.current.smallBold,
//                            color = Color(0xFFD00809)
//                        )
//                        Text("kishore.rathika5304@gmail.com", style = LocalCustomTypography.current.mini)
//                    }
//                }
//                Row(
//                    modifier = Modifier.padding(top = 50.dp),
//                    horizontalArrangement = Arrangement.spacedBy(30.dp),
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Image(
//                        modifier = Modifier.size(24.dp),
//                        painter = painterResource(Res.drawable.ic_contact_linkedin),
//                        contentDescription = "Linked Icon"
//                    )
//                    Column {
//                        Text(
//                            "Linked In",
//                            style = LocalCustomTypography.current.smallBold,
//                            color = Color(0xFFD00809)
//                        )
//                        Text("kishore.rathika5304@gmail.com", style = LocalCustomTypography.current.mini)
//                    }
//                }
//            }
//        }
//    }
////        Image(
////            modifier = Modifier.fillMaxWidth().clip(RoundedCornerShape(10.dp)),
////            painter = painterResource(Res.drawable.ic_contact_location_maps),
////            contentDescription = "Maps Image"
////        )
//    Box {
//
//    }
//}

@Composable
fun ContactItem(text: String, iconResId: DrawableResource, onItemClicked: () -> Unit) {
    Row(
        modifier = Modifier.clickable {
            onItemClicked()
        },
        horizontalArrangement = Arrangement.spacedBy(20.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            modifier = Modifier.size(24.dp),
            painter = painterResource(iconResId),
            contentDescription = "Contact Icon"
        )
        Text(text = text, style = LocalCustomTypography.current.regular)
    }

}