package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.about_me_character
import org.jetbrains.compose.resources.painterResource

@Composable
fun AboutMeScreen() {
    Box(modifier = Modifier.fillMaxSize().padding(20.dp), contentAlignment = Alignment.Center)
    {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier.weight(0.5f),
                contentDescription = "About Me Character",
                painter = painterResource(
                    Res.drawable.about_me_character
                )
            )
            Box(modifier = Modifier.weight(0.5f)) {
                Column(
                    verticalArrangement = Arrangement.spacedBy(30.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
//                    Text("My Story", style = LocalCustomTypography.current.regular)
                    ChatScreen()
                }
            }
        }
    }
}