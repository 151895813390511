package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Star
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.Project
import com.cmp.portfolio.projectPreviewItems
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDesktopProjectPreviewScreen() {
    var currentProjectPreview by remember { mutableStateOf(Project.FREEDOM) }
    Column(
        modifier = Modifier.fillMaxSize().padding(10.dp),
        verticalArrangement = Arrangement.spacedBy(10.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        BoxWithConstraints(
            modifier = Modifier.fillMaxWidth().clip(RoundedCornerShape(10.dp))
                .background(Color(0XFF0085FF).copy(alpha = 0.05f))
        ) {
            LazyVerticalGrid(
                columns = GridCells.Fixed(4),
                modifier = Modifier.fillMaxWidth().padding(10.dp),
                verticalArrangement = Arrangement.SpaceEvenly,
            ) {
                items(projectPreviewItems) { projectPreviewData ->
                    ProjectPreviewItem(
                        projectPreviewData.imageResId,
                        projectPreviewData.title,
                        projectPreviewData.category,
                        projectPreviewData.userCount,
                        isHighlighted = projectPreviewData.tag == currentProjectPreview,
                        onItemClicked = {
                            currentProjectPreview = projectPreviewData.tag
                        }
                    )
                }
            }
        }
        NewDesktopProjectDetailedScreen(
            currentProjectPreview,
            onArrowClicked = { currentProjectPreview = it })
    }

}

@Composable
private fun ProjectPreviewItem(
    logo: DrawableResource,
    title: String,
    description: String,
    userCount: String,
    isHighlighted: Boolean,
    onItemClicked: () -> Unit
) {
    Box(
        modifier = if (isHighlighted) Modifier
            .graphicsLayer(
                shadowElevation = 10f,
                shape = RoundedCornerShape(20.dp),
                clip = false,
                ambientShadowColor = Color(0XFF0085FF).copy(alpha = 0.25f),
                spotShadowColor = Color(0XFF0085FF).copy(alpha = 0.25f)
            )
            .background(color = Color.White, shape = RoundedCornerShape(20.dp))
            .clip(RoundedCornerShape(20.dp))
            .clickable { onItemClicked.invoke() }.padding(8.dp)
        else
            Modifier.background(color = Color.Transparent, shape = RoundedCornerShape(20.dp))
                .clip(RoundedCornerShape(20.dp))
                .clickable { onItemClicked.invoke() }
                .padding(8.dp)
    )
    {
        Row(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(10.dp)
        ) {
            Image(
                modifier = Modifier.size(72.dp),
                painter = painterResource(logo),
                contentDescription = "App logo"
            )
            Column(
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.spacedBy(2.dp)
            ) {
                Text(title, style = LocalCustomTypography.current.smallBold)
                Text(description, style = LocalCustomTypography.current.small)
                Text(userCount, style = LocalCustomTypography.current.small)
            }
        }
    }
}
