package com.cmp.portfolio.mobile

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.contactMeLocation
import com.cmp.portfolio.sp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_contact_linkedin
import composemultiplatform.composeapp.generated.resources.ic_contact_location
import composemultiplatform.composeapp.generated.resources.ic_contact_mail
import org.jetbrains.compose.resources.painterResource

@Composable
fun ConnectScreen( onSendEmailClicked: () -> Unit,  onLinkedInClicked: () -> Unit) {
    Card(
        modifier = Modifier.fillMaxWidth(),
        colors = CardColors(
            containerColor = Color.Black,
            contentColor = Color.White,
            disabledContentColor = Color.Transparent,
            disabledContainerColor = Color.Transparent
        ),
        shape = RoundedCornerShape(
            topStart = 10.dp,
            topEnd = 10.dp,
            bottomStart = 0.dp,
            bottomEnd = 0.dp
        )
    )
    {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 20.dp, vertical = 50.dp),
            verticalArrangement = Arrangement.spacedBy(20.dp)
        ) {
            Row {
                Text(
                    "Let's Connect",
                    style = TextStyle(fontWeight = FontWeight.Bold, fontSize = 24.sp)
                )
            }
            Row(
                modifier = Modifier.clickable(interactionSource = MutableInteractionSource(), indication = null) { onSendEmailClicked.invoke() },
                horizontalArrangement = Arrangement.spacedBy(10.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier
                        .clip(CircleShape)
                        .background(Color.White),
                    contentAlignment = Alignment.Center
                )
                {
                    Image(
                        modifier = Modifier
                            .size(24.dp)
                            .padding(5.dp),
                        painter = painterResource(Res.drawable.ic_contact_mail),
                        contentDescription = "Mail Icon"
                    )
                }
                Text(
                    "Kishore.rathika5304@gmail.com",
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp
                )
            }
            Row(
                modifier = Modifier.clickable(interactionSource = MutableInteractionSource(), indication = null) { onLinkedInClicked.invoke() },
                horizontalArrangement = Arrangement.spacedBy(10.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier
                        .clip(CircleShape)
                        .background(Color.White),
                    contentAlignment = Alignment.Center
                )
                {
                    Image(
                        modifier = Modifier
                            .size(24.dp)
                            .padding(5.dp),
                        painter = painterResource(Res.drawable.ic_contact_linkedin),
                        contentDescription = "LinkedIn Icon"
                    )
                }
                Text("kishore-kumarasamy", fontWeight = FontWeight.Bold, fontSize = 14.sp)
            }
            Row(
                horizontalArrangement = Arrangement.spacedBy(10.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier
                        .clip(CircleShape)
                        .background(Color.White),
                    contentAlignment = Alignment.Center
                )
                {
                    Image(
                        modifier = Modifier
                            .size(24.dp)
                            .padding(5.dp),
                        painter = painterResource(Res.drawable.ic_contact_location),
                        contentDescription = "Location Icon"
                    )
                }
                Text(contactMeLocation, fontWeight = FontWeight.Bold, fontSize = 14.sp)
            }
        }
    }
}