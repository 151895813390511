package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.ExperienceData
import com.cmp.portfolio.experienceItems
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDesktopExperienceScreen() {
    Box(modifier = Modifier.padding(30.dp))
    {
        LazyColumn(modifier = Modifier.fillMaxSize()) {
            items(experienceItems)
            {
                NewDesktopExperienceItem(it)
            }
        }
    }
}

@Composable
fun NewDesktopExperienceItem(experienceData: ExperienceData) {
    val uriHandler = LocalUriHandler.current
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.spacedBy(10.dp)
    ) {
        Image(
            modifier = Modifier.size(48.dp).clip(RoundedCornerShape(10.dp))
                .clickable { uriHandler.openUri(experienceData.companyUrl) },
            painter = painterResource(experienceData.iconResource),
            contentDescription = "Experience Icon"
        )
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(12.dp)
        ) {
            Column(verticalArrangement = Arrangement.spacedBy(3.dp)) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Text(
                        modifier = Modifier.clickable { uriHandler.openUri(experienceData.companyUrl) },
                        text = experienceData.title,
                        style = LocalCustomTypography.current.regularBold
                    )
                    Text(
                        experienceData.duration,
                        style = LocalCustomTypography.current.smallBold
                    )
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Text(
                        text = experienceData.designation,
                        style = LocalCustomTypography.current.small,
                        color = Color.Black.copy(0.8f)
                    )
                    Text(
                        text = experienceData.type,
                        style = LocalCustomTypography.current.small,
                        color = Color.Black.copy(0.8f)
                    )
                }
            }

            Text(
                experienceData.description,
                style = LocalCustomTypography.current.small,
                color = Color.Black.copy(0.6f)
            )
            HorizontalDivider(modifier = Modifier.padding(top = 10.dp, bottom = 20.dp))
        }
    }
}