package com.cmp.portfolio

enum class Project {
    DEFAULT,
    FREEDOM,
    INTRUDER_CAPTURE,
    NOTEBOOK,
    GPS_LOCKER,
    AGAM,
    FOODY,
    APP_USAGE_TRACKER,
    POMODORO_TASK_TIMER
}