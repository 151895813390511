package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.ContentTransform
import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.animation.SizeTransform
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.TextUnit

@OptIn(ExperimentalAnimationApi::class)
@Composable
fun AnimatedCounter(
    count: Int,
    supportText: String,
    modifier: Modifier = Modifier,
    style: TextStyle = LocalCustomTypography.current.regularBold,
    color: Color = Color.Black,
    fontSize: TextUnit,
) {
    Column(
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        AnimatedContent(
            targetState = count,
            transitionSpec = {
                addAnimation().using(
                    SizeTransform(clip = false)
                )
            }
        ) { targetCount ->
            Text(
                text = "$targetCount$supportText",
                style = style,
                color = color,
                fontSize = fontSize
            )
        }
    }
}

@ExperimentalAnimationApi
fun addAnimation(duration: Int = 300): ContentTransform {
    return (slideInVertically() { height -> height } + fadeIn(
    )).togetherWith(slideOutVertically() { height -> -height } + fadeOut(
    ))
}