@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package composemultiplatform.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object JsMainDrawable0 {
  public val about_me_character: DrawableResource by 
      lazy { init_about_me_character() }

  public val agam_logo: DrawableResource by 
      lazy { init_agam_logo() }

  public val app_usage_tracker_logo: DrawableResource by 
      lazy { init_app_usage_tracker_logo() }

  public val available_on_app_store: DrawableResource by 
      lazy { init_available_on_app_store() }

  public val available_on_playstore: DrawableResource by 
      lazy { init_available_on_playstore() }

  public val contact_location_map: DrawableResource by 
      lazy { init_contact_location_map() }

  public val foody_logo: DrawableResource by 
      lazy { init_foody_logo() }

  public val freedom_logo: DrawableResource by 
      lazy { init_freedom_logo() }

  public val gps_locker_logo: DrawableResource by 
      lazy { init_gps_locker_logo() }

  public val ic_about_me: DrawableResource by 
      lazy { init_ic_about_me() }

  public val ic_about_me_1: DrawableResource by 
      lazy { init_ic_about_me_1() }

  public val ic_about_me_2: DrawableResource by 
      lazy { init_ic_about_me_2() }

  public val ic_about_me_3: DrawableResource by 
      lazy { init_ic_about_me_3() }

  public val ic_about_me_4: DrawableResource by 
      lazy { init_ic_about_me_4() }

  public val ic_auto_projects: DrawableResource by 
      lazy { init_ic_auto_projects() }

  public val ic_automotive_project: DrawableResource by 
      lazy { init_ic_automotive_project() }

  public val ic_contact_linked_in_1: DrawableResource by 
      lazy { init_ic_contact_linked_in_1() }

  public val ic_contact_linkedin: DrawableResource by 
      lazy { init_ic_contact_linkedin() }

  public val ic_contact_location: DrawableResource by 
      lazy { init_ic_contact_location() }

  public val ic_contact_location_maps: DrawableResource by 
      lazy { init_ic_contact_location_maps() }

  public val ic_contact_mail: DrawableResource by 
      lazy { init_ic_contact_mail() }

  public val ic_contact_mail_1: DrawableResource by 
      lazy { init_ic_contact_mail_1() }

  public val ic_contact_sitting_avatar: DrawableResource by 
      lazy { init_ic_contact_sitting_avatar() }

  public val ic_download: DrawableResource by 
      lazy { init_ic_download() }

  public val ic_experience: DrawableResource by 
      lazy { init_ic_experience() }

  public val ic_experience_education: DrawableResource by 
      lazy { init_ic_experience_education() }

  public val ic_launcher_background: DrawableResource by 
      lazy { init_ic_launcher_background() }

  public val ic_launcher_foreground: DrawableResource by 
      lazy { init_ic_launcher_foreground() }

  public val ic_person_skills: DrawableResource by 
      lazy { init_ic_person_skills() }

  public val ic_projcets: DrawableResource by 
      lazy { init_ic_projcets() }

  public val ic_projects_1: DrawableResource by 
      lazy { init_ic_projects_1() }

  public val ic_projects_2: DrawableResource by 
      lazy { init_ic_projects_2() }

  public val ic_skill_3d_avatar: DrawableResource by 
      lazy { init_ic_skill_3d_avatar() }

  public val ic_skill_android_sdk: DrawableResource by 
      lazy { init_ic_skill_android_sdk() }

  public val ic_skill_architecture: DrawableResource by 
      lazy { init_ic_skill_architecture() }

  public val ic_skill_canbus: DrawableResource by 
      lazy { init_ic_skill_canbus() }

  public val ic_skill_di: DrawableResource by 
      lazy { init_ic_skill_di() }

  public val ic_skill_etoe_testing: DrawableResource by 
      lazy { init_ic_skill_etoe_testing() }

  public val ic_skill_file_system: DrawableResource by 
      lazy { init_ic_skill_file_system() }

  public val ic_skill_firebase: DrawableResource by 
      lazy { init_ic_skill_firebase() }

  public val ic_skill_git: DrawableResource by 
      lazy { init_ic_skill_git() }

  public val ic_skill_github: DrawableResource by 
      lazy { init_ic_skill_github() }

  public val ic_skill_gitlab: DrawableResource by 
      lazy { init_ic_skill_gitlab() }

  public val ic_skill_hmi: DrawableResource by 
      lazy { init_ic_skill_hmi() }

  public val ic_skill_http: DrawableResource by 
      lazy { init_ic_skill_http() }

  public val ic_skill_infotainment: DrawableResource by 
      lazy { init_ic_skill_infotainment() }

  public val ic_skill_integration_testing: DrawableResource by 
      lazy { init_ic_skill_integration_testing() }

  public val ic_skill_java: DrawableResource by 
      lazy { init_ic_skill_java() }

  public val ic_skill_jetpack_compose: DrawableResource by 
      lazy { init_ic_skill_jetpack_compose() }

  public val ic_skill_multithread: DrawableResource by 
      lazy { init_ic_skill_multithread() }

  public val ic_skill_mysql: DrawableResource by 
      lazy { init_ic_skill_mysql() }

  public val ic_skill_phone: DrawableResource by 
      lazy { init_ic_skill_phone() }

  public val ic_skill_php: DrawableResource by 
      lazy { init_ic_skill_php() }

  public val ic_skill_playstore: DrawableResource by 
      lazy { init_ic_skill_playstore() }

  public val ic_skill_preference: DrawableResource by 
      lazy { init_ic_skill_preference() }

  public val ic_skill_restful_api: DrawableResource by 
      lazy { init_ic_skill_restful_api() }

  public val ic_skill_retrofit: DrawableResource by 
      lazy { init_ic_skill_retrofit() }

  public val ic_skill_room_db: DrawableResource by 
      lazy { init_ic_skill_room_db() }

  public val ic_skill_ros: DrawableResource by 
      lazy { init_ic_skill_ros() }

  public val ic_skill_signed_apk: DrawableResource by 
      lazy { init_ic_skill_signed_apk() }

  public val ic_skill_sqlite: DrawableResource by 
      lazy { init_ic_skill_sqlite() }

  public val ic_skill_tablet: DrawableResource by 
      lazy { init_ic_skill_tablet() }

  public val ic_skill_unit_testing: DrawableResource by 
      lazy { init_ic_skill_unit_testing() }

  public val ic_skill_watch: DrawableResource by 
      lazy { init_ic_skill_watch() }

  public val ic_skill_xml: DrawableResource by 
      lazy { init_ic_skill_xml() }

  public val ic_start_up_logo_place_holder: DrawableResource by 
      lazy { init_ic_start_up_logo_place_holder() }

  public val ic_tab_dot: DrawableResource by 
      lazy { init_ic_tab_dot() }

  public val ic_tech_stack: DrawableResource by 
      lazy { init_ic_tech_stack() }

  public val ic_tools_android_studio: DrawableResource by 
      lazy { init_ic_tools_android_studio() }

  public val ic_tools_figma: DrawableResource by 
      lazy { init_ic_tools_figma() }

  public val ic_tools_linear: DrawableResource by 
      lazy { init_ic_tools_linear() }

  public val ic_tools_netlify: DrawableResource by 
      lazy { init_ic_tools_netlify() }

  public val ic_tools_notion: DrawableResource by 
      lazy { init_ic_tools_notion() }

  public val ic_tools_postman: DrawableResource by 
      lazy { init_ic_tools_postman() }

  public val ic_tools_slack: DrawableResource by 
      lazy { init_ic_tools_slack() }

  public val ic_tools_teams: DrawableResource by 
      lazy { init_ic_tools_teams() }

  public val ic_tools_xcode: DrawableResource by 
      lazy { init_ic_tools_xcode() }

  public val ic_topic_skill: DrawableResource by 
      lazy { init_ic_topic_skill() }

  public val ic_vehicle_info: DrawableResource by 
      lazy { init_ic_vehicle_info() }

  public val ic_vehicle_info_background: DrawableResource by 
      lazy { init_ic_vehicle_info_background() }

  public val ic_vehicle_info_real_vehicle: DrawableResource by 
      lazy { init_ic_vehicle_info_real_vehicle() }

  public val ic_vehicle_infotainment: DrawableResource by 
      lazy { init_ic_vehicle_infotainment() }

  public val ic_vehicle_infotainment_with_car_background: DrawableResource by 
      lazy { init_ic_vehicle_infotainment_with_car_background() }

  public val intruder_capture_logo: DrawableResource by 
      lazy { init_intruder_capture_logo() }

  public val left_arrow: DrawableResource by 
      lazy { init_left_arrow() }

  public val multivariate_logo: DrawableResource by 
      lazy { init_multivariate_logo() }

  public val my_city_events_logo: DrawableResource by 
      lazy { init_my_city_events_logo() }

  public val new_profile_image: DrawableResource by 
      lazy { init_new_profile_image() }

  public val new_profile_picture_1: DrawableResource by 
      lazy { init_new_profile_picture_1() }

  public val pomodoro_task_timer_logo: DrawableResource by 
      lazy { init_pomodoro_task_timer_logo() }

  public val profile_image: DrawableResource by 
      lazy { init_profile_image() }

  public val profile_image_with_half_circle: DrawableResource by 
      lazy { init_profile_image_with_half_circle() }

  public val profile_image_without_background: DrawableResource by 
      lazy { init_profile_image_without_background() }

  public val project_agam: DrawableResource by 
      lazy { init_project_agam() }

  public val project_app_usage_tracker: DrawableResource by 
      lazy { init_project_app_usage_tracker() }

  public val project_foody: DrawableResource by 
      lazy { init_project_foody() }

  public val project_freedom: DrawableResource by 
      lazy { init_project_freedom() }

  public val project_gps_locker: DrawableResource by 
      lazy { init_project_gps_locker() }

  public val project_img_boson: DrawableResource by 
      lazy { init_project_img_boson() }

  public val project_img_intruder_capture: DrawableResource by 
      lazy { init_project_img_intruder_capture() }

  public val project_img_notebook_watch: DrawableResource by 
      lazy { init_project_img_notebook_watch() }

  public val project_pomodoro_task_timer: DrawableResource by 
      lazy { init_project_pomodoro_task_timer() }

  public val right_arrow: DrawableResource by 
      lazy { init_right_arrow() }

  public val skill_icon_kotlin: DrawableResource by 
      lazy { init_skill_icon_kotlin() }

  public val university_of_waterloo_logo: DrawableResource by 
      lazy { init_university_of_waterloo_logo() }

  public val vcet_logo: DrawableResource by 
      lazy { init_vcet_logo() }

  public val zoho_logo: DrawableResource by 
      lazy { init_zoho_logo() }

  public val zoho_notebook_logo: DrawableResource by 
      lazy { init_zoho_notebook_logo() }
}

internal val Res.drawable.about_me_character: DrawableResource
  get() = JsMainDrawable0.about_me_character

private fun init_about_me_character(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:about_me_character",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/about_me_character.png", -1, -1),
    )
)

internal val Res.drawable.agam_logo: DrawableResource
  get() = JsMainDrawable0.agam_logo

private fun init_agam_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:agam_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/agam_logo.png", -1, -1),
    )
)

internal val Res.drawable.app_usage_tracker_logo: DrawableResource
  get() = JsMainDrawable0.app_usage_tracker_logo

private fun init_app_usage_tracker_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_usage_tracker_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/app_usage_tracker_logo.png", -1, -1),
    )
)

internal val Res.drawable.available_on_app_store: DrawableResource
  get() = JsMainDrawable0.available_on_app_store

private fun init_available_on_app_store(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:available_on_app_store",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/available_on_app_store.png", -1, -1),
    )
)

internal val Res.drawable.available_on_playstore: DrawableResource
  get() = JsMainDrawable0.available_on_playstore

private fun init_available_on_playstore(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:available_on_playstore",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/available_on_playstore.png", -1, -1),
    )
)

internal val Res.drawable.contact_location_map: DrawableResource
  get() = JsMainDrawable0.contact_location_map

private fun init_contact_location_map(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_location_map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/contact_location_map.jpeg", -1, -1),
    )
)

internal val Res.drawable.foody_logo: DrawableResource
  get() = JsMainDrawable0.foody_logo

private fun init_foody_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:foody_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/foody_logo.png", -1, -1),
    )
)

internal val Res.drawable.freedom_logo: DrawableResource
  get() = JsMainDrawable0.freedom_logo

private fun init_freedom_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:freedom_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/freedom_logo.png", -1, -1),
    )
)

internal val Res.drawable.gps_locker_logo: DrawableResource
  get() = JsMainDrawable0.gps_locker_logo

private fun init_gps_locker_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:gps_locker_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/gps_locker_logo.png", -1, -1),
    )
)

internal val Res.drawable.ic_about_me: DrawableResource
  get() = JsMainDrawable0.ic_about_me

private fun init_ic_about_me(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_about_me",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_about_me.png", -1, -1),
    )
)

internal val Res.drawable.ic_about_me_1: DrawableResource
  get() = JsMainDrawable0.ic_about_me_1

private fun init_ic_about_me_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_about_me_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_about_me_1.png", -1, -1),
    )
)

internal val Res.drawable.ic_about_me_2: DrawableResource
  get() = JsMainDrawable0.ic_about_me_2

private fun init_ic_about_me_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_about_me_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_about_me_2.png", -1, -1),
    )
)

internal val Res.drawable.ic_about_me_3: DrawableResource
  get() = JsMainDrawable0.ic_about_me_3

private fun init_ic_about_me_3(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_about_me_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_about_me_3.png", -1, -1),
    )
)

internal val Res.drawable.ic_about_me_4: DrawableResource
  get() = JsMainDrawable0.ic_about_me_4

private fun init_ic_about_me_4(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_about_me_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_about_me_4.png", -1, -1),
    )
)

internal val Res.drawable.ic_auto_projects: DrawableResource
  get() = JsMainDrawable0.ic_auto_projects

private fun init_ic_auto_projects(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_auto_projects",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_auto_projects.png", -1, -1),
    )
)

internal val Res.drawable.ic_automotive_project: DrawableResource
  get() = JsMainDrawable0.ic_automotive_project

private fun init_ic_automotive_project(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_automotive_project",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_automotive_project.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_linked_in_1: DrawableResource
  get() = JsMainDrawable0.ic_contact_linked_in_1

private fun init_ic_contact_linked_in_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_linked_in_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_linked_in_1.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_linkedin: DrawableResource
  get() = JsMainDrawable0.ic_contact_linkedin

private fun init_ic_contact_linkedin(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_linkedin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_linkedin.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_location: DrawableResource
  get() = JsMainDrawable0.ic_contact_location

private fun init_ic_contact_location(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_location",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_location.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_location_maps: DrawableResource
  get() = JsMainDrawable0.ic_contact_location_maps

private fun init_ic_contact_location_maps(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_location_maps",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_location_maps.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_mail: DrawableResource
  get() = JsMainDrawable0.ic_contact_mail

private fun init_ic_contact_mail(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_mail.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_mail_1: DrawableResource
  get() = JsMainDrawable0.ic_contact_mail_1

private fun init_ic_contact_mail_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_mail_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_mail_1.png", -1, -1),
    )
)

internal val Res.drawable.ic_contact_sitting_avatar: DrawableResource
  get() = JsMainDrawable0.ic_contact_sitting_avatar

private fun init_ic_contact_sitting_avatar(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_contact_sitting_avatar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_contact_sitting_avatar.png", -1, -1),
    )
)

internal val Res.drawable.ic_download: DrawableResource
  get() = JsMainDrawable0.ic_download

private fun init_ic_download(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_download",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_download.png", -1, -1),
    )
)

internal val Res.drawable.ic_experience: DrawableResource
  get() = JsMainDrawable0.ic_experience

private fun init_ic_experience(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_experience",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_experience.png", -1, -1),
    )
)

internal val Res.drawable.ic_experience_education: DrawableResource
  get() = JsMainDrawable0.ic_experience_education

private fun init_ic_experience_education(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_experience_education",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_experience_education.png", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_background: DrawableResource
  get() = JsMainDrawable0.ic_launcher_background

private fun init_ic_launcher_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_launcher_background.xml", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_foreground: DrawableResource
  get() = JsMainDrawable0.ic_launcher_foreground

private fun init_ic_launcher_foreground(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_foreground",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_launcher_foreground.xml", -1, -1),
    )
)

internal val Res.drawable.ic_person_skills: DrawableResource
  get() = JsMainDrawable0.ic_person_skills

private fun init_ic_person_skills(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_person_skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_person_skills.png", -1, -1),
    )
)

internal val Res.drawable.ic_projcets: DrawableResource
  get() = JsMainDrawable0.ic_projcets

private fun init_ic_projcets(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_projcets",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_projcets.png", -1, -1),
    )
)

internal val Res.drawable.ic_projects_1: DrawableResource
  get() = JsMainDrawable0.ic_projects_1

private fun init_ic_projects_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_projects_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_projects_1.png", -1, -1),
    )
)

internal val Res.drawable.ic_projects_2: DrawableResource
  get() = JsMainDrawable0.ic_projects_2

private fun init_ic_projects_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_projects_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_projects_2.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_3d_avatar: DrawableResource
  get() = JsMainDrawable0.ic_skill_3d_avatar

private fun init_ic_skill_3d_avatar(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_3d_avatar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_3d_avatar.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_android_sdk: DrawableResource
  get() = JsMainDrawable0.ic_skill_android_sdk

private fun init_ic_skill_android_sdk(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_android_sdk",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_android_sdk.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_architecture: DrawableResource
  get() = JsMainDrawable0.ic_skill_architecture

private fun init_ic_skill_architecture(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_architecture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_architecture.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_canbus: DrawableResource
  get() = JsMainDrawable0.ic_skill_canbus

private fun init_ic_skill_canbus(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_canbus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_canbus.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_di: DrawableResource
  get() = JsMainDrawable0.ic_skill_di

private fun init_ic_skill_di(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_di",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_di.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_etoe_testing: DrawableResource
  get() = JsMainDrawable0.ic_skill_etoe_testing

private fun init_ic_skill_etoe_testing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_etoe_testing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_etoe_testing.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_file_system: DrawableResource
  get() = JsMainDrawable0.ic_skill_file_system

private fun init_ic_skill_file_system(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_file_system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_file_system.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_firebase: DrawableResource
  get() = JsMainDrawable0.ic_skill_firebase

private fun init_ic_skill_firebase(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_firebase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_firebase.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_git: DrawableResource
  get() = JsMainDrawable0.ic_skill_git

private fun init_ic_skill_git(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_git",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_git.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_github: DrawableResource
  get() = JsMainDrawable0.ic_skill_github

private fun init_ic_skill_github(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_github.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_gitlab: DrawableResource
  get() = JsMainDrawable0.ic_skill_gitlab

private fun init_ic_skill_gitlab(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_gitlab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_gitlab.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_hmi: DrawableResource
  get() = JsMainDrawable0.ic_skill_hmi

private fun init_ic_skill_hmi(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_hmi",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_hmi.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_http: DrawableResource
  get() = JsMainDrawable0.ic_skill_http

private fun init_ic_skill_http(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_http",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_http.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_infotainment: DrawableResource
  get() = JsMainDrawable0.ic_skill_infotainment

private fun init_ic_skill_infotainment(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_infotainment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_infotainment.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_integration_testing: DrawableResource
  get() = JsMainDrawable0.ic_skill_integration_testing

private fun init_ic_skill_integration_testing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_integration_testing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_integration_testing.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_java: DrawableResource
  get() = JsMainDrawable0.ic_skill_java

private fun init_ic_skill_java(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_java",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_java.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_jetpack_compose: DrawableResource
  get() = JsMainDrawable0.ic_skill_jetpack_compose

private fun init_ic_skill_jetpack_compose(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_jetpack_compose",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_jetpack_compose.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_multithread: DrawableResource
  get() = JsMainDrawable0.ic_skill_multithread

private fun init_ic_skill_multithread(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_multithread",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_multithread.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_mysql: DrawableResource
  get() = JsMainDrawable0.ic_skill_mysql

private fun init_ic_skill_mysql(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_mysql",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_mysql.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_phone: DrawableResource
  get() = JsMainDrawable0.ic_skill_phone

private fun init_ic_skill_phone(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_phone.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_php: DrawableResource
  get() = JsMainDrawable0.ic_skill_php

private fun init_ic_skill_php(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_php",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_php.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_playstore: DrawableResource
  get() = JsMainDrawable0.ic_skill_playstore

private fun init_ic_skill_playstore(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_playstore",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_playstore.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_preference: DrawableResource
  get() = JsMainDrawable0.ic_skill_preference

private fun init_ic_skill_preference(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_preference",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_preference.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_restful_api: DrawableResource
  get() = JsMainDrawable0.ic_skill_restful_api

private fun init_ic_skill_restful_api(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_restful_api",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_restful_api.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_retrofit: DrawableResource
  get() = JsMainDrawable0.ic_skill_retrofit

private fun init_ic_skill_retrofit(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_retrofit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_retrofit.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_room_db: DrawableResource
  get() = JsMainDrawable0.ic_skill_room_db

private fun init_ic_skill_room_db(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_room_db",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_room_db.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_ros: DrawableResource
  get() = JsMainDrawable0.ic_skill_ros

private fun init_ic_skill_ros(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_ros",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_ros.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_signed_apk: DrawableResource
  get() = JsMainDrawable0.ic_skill_signed_apk

private fun init_ic_skill_signed_apk(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_signed_apk",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_signed_apk.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_sqlite: DrawableResource
  get() = JsMainDrawable0.ic_skill_sqlite

private fun init_ic_skill_sqlite(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_sqlite",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_sqlite.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_tablet: DrawableResource
  get() = JsMainDrawable0.ic_skill_tablet

private fun init_ic_skill_tablet(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_tablet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_tablet.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_unit_testing: DrawableResource
  get() = JsMainDrawable0.ic_skill_unit_testing

private fun init_ic_skill_unit_testing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_unit_testing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_unit_testing.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_watch: DrawableResource
  get() = JsMainDrawable0.ic_skill_watch

private fun init_ic_skill_watch(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_watch",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_watch.png", -1, -1),
    )
)

internal val Res.drawable.ic_skill_xml: DrawableResource
  get() = JsMainDrawable0.ic_skill_xml

private fun init_ic_skill_xml(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skill_xml",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_skill_xml.png", -1, -1),
    )
)

internal val Res.drawable.ic_start_up_logo_place_holder: DrawableResource
  get() = JsMainDrawable0.ic_start_up_logo_place_holder

private fun init_ic_start_up_logo_place_holder(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_start_up_logo_place_holder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_start_up_logo_place_holder.jpg", -1, -1),
    )
)

internal val Res.drawable.ic_tab_dot: DrawableResource
  get() = JsMainDrawable0.ic_tab_dot

private fun init_ic_tab_dot(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tab_dot",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tab_dot.png", -1, -1),
    )
)

internal val Res.drawable.ic_tech_stack: DrawableResource
  get() = JsMainDrawable0.ic_tech_stack

private fun init_ic_tech_stack(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tech_stack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tech_stack.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_android_studio: DrawableResource
  get() = JsMainDrawable0.ic_tools_android_studio

private fun init_ic_tools_android_studio(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_android_studio",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_android_studio.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_figma: DrawableResource
  get() = JsMainDrawable0.ic_tools_figma

private fun init_ic_tools_figma(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_figma",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_figma.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_linear: DrawableResource
  get() = JsMainDrawable0.ic_tools_linear

private fun init_ic_tools_linear(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_linear",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_linear.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_netlify: DrawableResource
  get() = JsMainDrawable0.ic_tools_netlify

private fun init_ic_tools_netlify(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_netlify",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_netlify.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_notion: DrawableResource
  get() = JsMainDrawable0.ic_tools_notion

private fun init_ic_tools_notion(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_notion",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_notion.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_postman: DrawableResource
  get() = JsMainDrawable0.ic_tools_postman

private fun init_ic_tools_postman(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_postman",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_postman.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_slack: DrawableResource
  get() = JsMainDrawable0.ic_tools_slack

private fun init_ic_tools_slack(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_slack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_slack.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_teams: DrawableResource
  get() = JsMainDrawable0.ic_tools_teams

private fun init_ic_tools_teams(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_teams.png", -1, -1),
    )
)

internal val Res.drawable.ic_tools_xcode: DrawableResource
  get() = JsMainDrawable0.ic_tools_xcode

private fun init_ic_tools_xcode(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tools_xcode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_tools_xcode.png", -1, -1),
    )
)

internal val Res.drawable.ic_topic_skill: DrawableResource
  get() = JsMainDrawable0.ic_topic_skill

private fun init_ic_topic_skill(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_topic_skill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_topic_skill.png", -1, -1),
    )
)

internal val Res.drawable.ic_vehicle_info: DrawableResource
  get() = JsMainDrawable0.ic_vehicle_info

private fun init_ic_vehicle_info(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_vehicle_info",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_vehicle_info.png", -1, -1),
    )
)

internal val Res.drawable.ic_vehicle_info_background: DrawableResource
  get() = JsMainDrawable0.ic_vehicle_info_background

private fun init_ic_vehicle_info_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_vehicle_info_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_vehicle_info_background.png", -1, -1),
    )
)

internal val Res.drawable.ic_vehicle_info_real_vehicle: DrawableResource
  get() = JsMainDrawable0.ic_vehicle_info_real_vehicle

private fun init_ic_vehicle_info_real_vehicle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_vehicle_info_real_vehicle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_vehicle_info_real_vehicle.png", -1, -1),
    )
)

internal val Res.drawable.ic_vehicle_infotainment: DrawableResource
  get() = JsMainDrawable0.ic_vehicle_infotainment

private fun init_ic_vehicle_infotainment(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_vehicle_infotainment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_vehicle_infotainment.png", -1, -1),
    )
)

internal val Res.drawable.ic_vehicle_infotainment_with_car_background: DrawableResource
  get() = JsMainDrawable0.ic_vehicle_infotainment_with_car_background

private fun init_ic_vehicle_infotainment_with_car_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_vehicle_infotainment_with_car_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/ic_vehicle_infotainment_with_car_background.jpeg", -1, -1),
    )
)

internal val Res.drawable.intruder_capture_logo: DrawableResource
  get() = JsMainDrawable0.intruder_capture_logo

private fun init_intruder_capture_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:intruder_capture_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/intruder_capture_logo.png", -1, -1),
    )
)

internal val Res.drawable.left_arrow: DrawableResource
  get() = JsMainDrawable0.left_arrow

private fun init_left_arrow(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:left_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/left_arrow.png", -1, -1),
    )
)

internal val Res.drawable.multivariate_logo: DrawableResource
  get() = JsMainDrawable0.multivariate_logo

private fun init_multivariate_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:multivariate_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/multivariate_logo.png", -1, -1),
    )
)

internal val Res.drawable.my_city_events_logo: DrawableResource
  get() = JsMainDrawable0.my_city_events_logo

private fun init_my_city_events_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:my_city_events_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/my_city_events_logo.png", -1, -1),
    )
)

internal val Res.drawable.new_profile_image: DrawableResource
  get() = JsMainDrawable0.new_profile_image

private fun init_new_profile_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:new_profile_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/new_profile_image.png", -1, -1),
    )
)

internal val Res.drawable.new_profile_picture_1: DrawableResource
  get() = JsMainDrawable0.new_profile_picture_1

private fun init_new_profile_picture_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:new_profile_picture_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/new_profile_picture_1.png", -1, -1),
    )
)

internal val Res.drawable.pomodoro_task_timer_logo: DrawableResource
  get() = JsMainDrawable0.pomodoro_task_timer_logo

private fun init_pomodoro_task_timer_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:pomodoro_task_timer_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/pomodoro_task_timer_logo.png", -1, -1),
    )
)

internal val Res.drawable.profile_image: DrawableResource
  get() = JsMainDrawable0.profile_image

private fun init_profile_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/profile_image.jpg", -1, -1),
    )
)

internal val Res.drawable.profile_image_with_half_circle: DrawableResource
  get() = JsMainDrawable0.profile_image_with_half_circle

private fun init_profile_image_with_half_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_image_with_half_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/profile_image_with_half_circle.png", -1, -1),
    )
)

internal val Res.drawable.profile_image_without_background: DrawableResource
  get() = JsMainDrawable0.profile_image_without_background

private fun init_profile_image_without_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_image_without_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/profile_image_without_background.png", -1, -1),
    )
)

internal val Res.drawable.project_agam: DrawableResource
  get() = JsMainDrawable0.project_agam

private fun init_project_agam(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_agam",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_agam.png", -1, -1),
    )
)

internal val Res.drawable.project_app_usage_tracker: DrawableResource
  get() = JsMainDrawable0.project_app_usage_tracker

private fun init_project_app_usage_tracker(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_app_usage_tracker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_app_usage_tracker.png", -1, -1),
    )
)

internal val Res.drawable.project_foody: DrawableResource
  get() = JsMainDrawable0.project_foody

private fun init_project_foody(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_foody",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_foody.png", -1, -1),
    )
)

internal val Res.drawable.project_freedom: DrawableResource
  get() = JsMainDrawable0.project_freedom

private fun init_project_freedom(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_freedom",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_freedom.png", -1, -1),
    )
)

internal val Res.drawable.project_gps_locker: DrawableResource
  get() = JsMainDrawable0.project_gps_locker

private fun init_project_gps_locker(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_gps_locker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_gps_locker.png", -1, -1),
    )
)

internal val Res.drawable.project_img_boson: DrawableResource
  get() = JsMainDrawable0.project_img_boson

private fun init_project_img_boson(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_img_boson",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_img_boson.png", -1, -1),
    )
)

internal val Res.drawable.project_img_intruder_capture: DrawableResource
  get() = JsMainDrawable0.project_img_intruder_capture

private fun init_project_img_intruder_capture(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_img_intruder_capture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_img_intruder_capture.png", -1, -1),
    )
)

internal val Res.drawable.project_img_notebook_watch: DrawableResource
  get() = JsMainDrawable0.project_img_notebook_watch

private fun init_project_img_notebook_watch(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_img_notebook_watch",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_img_notebook_watch.png", -1, -1),
    )
)

internal val Res.drawable.project_pomodoro_task_timer: DrawableResource
  get() = JsMainDrawable0.project_pomodoro_task_timer

private fun init_project_pomodoro_task_timer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:project_pomodoro_task_timer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/project_pomodoro_task_timer.png", -1, -1),
    )
)

internal val Res.drawable.right_arrow: DrawableResource
  get() = JsMainDrawable0.right_arrow

private fun init_right_arrow(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:right_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/right_arrow.png", -1, -1),
    )
)

internal val Res.drawable.skill_icon_kotlin: DrawableResource
  get() = JsMainDrawable0.skill_icon_kotlin

private fun init_skill_icon_kotlin(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:skill_icon_kotlin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/skill_icon_kotlin.png", -1, -1),
    )
)

internal val Res.drawable.university_of_waterloo_logo: DrawableResource
  get() = JsMainDrawable0.university_of_waterloo_logo

private fun init_university_of_waterloo_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:university_of_waterloo_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/university_of_waterloo_logo.png", -1, -1),
    )
)

internal val Res.drawable.vcet_logo: DrawableResource
  get() = JsMainDrawable0.vcet_logo

private fun init_vcet_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:vcet_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/vcet_logo.png", -1, -1),
    )
)

internal val Res.drawable.zoho_logo: DrawableResource
  get() = JsMainDrawable0.zoho_logo

private fun init_zoho_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:zoho_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/zoho_logo.png", -1, -1),
    )
)

internal val Res.drawable.zoho_notebook_logo: DrawableResource
  get() = JsMainDrawable0.zoho_notebook_logo

private fun init_zoho_notebook_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:zoho_notebook_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/composemultiplatform.composeapp.generated.resources/drawable/zoho_notebook_logo.png", -1, -1),
    )
)
