package com.cmp.portfolio

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.Layout
import kotlin.math.max

@Composable
fun MultiLineRow(
    modifier: Modifier = Modifier,
    horizontalArrangement: Arrangement.Horizontal = Arrangement.Start,
    verticalAlignment: Alignment.Vertical = Alignment.Top,
    content: @Composable () -> Unit
) {
    Layout(
        content = content,
        modifier = modifier
    ) { measurables, constraints ->
        val placeables = measurables.map { measurable ->
            measurable.measure(constraints)
        }

        // Initialize variables to manage layout
        val lineWidths = mutableListOf<Int>()
        var currentLineWidth = 0
        var currentLineHeight = 0
        val lineHeights = mutableListOf<Int>()

        // Calculate line widths and heights
        placeables.forEach { placeable ->
            if (currentLineWidth + placeable.width > constraints.maxWidth) {
                // Move to the next line
                lineWidths.add(currentLineWidth)
                lineHeights.add(currentLineHeight)
                currentLineWidth = placeable.width
                currentLineHeight = placeable.height
            } else {
                currentLineWidth += placeable.width
                currentLineHeight = max(currentLineHeight, placeable.height)
            }
        }
        // Add the last line width and height
        lineWidths.add(currentLineWidth)
        lineHeights.add(currentLineHeight)

        // Calculate the total height
        val totalHeight = lineHeights.sum()

        // Set the size of the layout
        layout(constraints.maxWidth, totalHeight) {
            var yOffset = 0
            var lineIndex = 0
            var xOffset = 0

            // Place the children
            placeables.forEach { placeable ->
                if (xOffset + placeable.width > constraints.maxWidth) {
                    // Move to the next line
                    yOffset += lineHeights[lineIndex]
                    xOffset = 0
                    lineIndex++
                }

                // Calculate x position based on horizontal arrangement
                val xPosition = when (horizontalArrangement) {
                    Arrangement.Start -> xOffset
                    Arrangement.Center -> (constraints.maxWidth - lineWidths[lineIndex]) / 2 + xOffset
                    Arrangement.End -> constraints.maxWidth - lineWidths[lineIndex] + xOffset
                    else -> xOffset // Default case
                }

                // Place the item
                placeable.placeRelative(xPosition, yOffset)

                // Update xOffset for the next item
                xOffset += placeable.width
            }
        }
    }
}