package com.cmp.portfolio.mobile

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.MultiLineRow
import com.cmp.portfolio.SkillsData
import com.cmp.portfolio.skillsItems
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun TechStackScreen() {
    var isSkillsExpanded by remember { mutableStateOf(false) }
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 10.dp),
        colors = CardColors(
            containerColor = Color.White,
            contentColor = Color.Black,
            Color.Transparent,
            Color.Transparent
        ),
        shape = RoundedCornerShape(
            topStart = 0.dp,
            topEnd = 10.dp,
            bottomStart = 10.dp,
            bottomEnd = 10.dp
        )
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 10.dp, end = 10.dp, top = 10.dp)
        ) {
            Column(Modifier.fillMaxWidth(), verticalArrangement = Arrangement.spacedBy(10.dp))
            {
                (if (isSkillsExpanded) skillsItems else skillsItems.subList(
                    0,
                    5
                )).forEach { skillsData ->
                    TechStackSet(skillsData)
                }
                if (!isSkillsExpanded) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(10.dp)
                            .clickable(
                                interactionSource = MutableInteractionSource(),
                                indication = null
                            ) {
                                isSkillsExpanded = true
                            }, contentAlignment = Alignment.Center
                    )
                    {
                        Row(
                            horizontalArrangement = Arrangement.spacedBy(5.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(Icons.Filled.ArrowDropDown, contentDescription = "Load More Icon")
                            Text(
                                text = "Read More",
                                fontWeight = FontWeight.Bold,
                                color = Color.Black
                            )
                        }

                    }
                }
            }
        }
    }
}

@Composable
private fun TechStackSet(skillData: SkillsData) {
    Column {
        Text(skillData.skillTitle, style = LocalCustomTypography.current.miniSemiBold)
        MultiLineRow {
            skillData.skillSets.forEach { skill ->
                Box(modifier = Modifier.padding(5.dp))
                {
                    TechStackItem(skill.title, skill.iconResource, skill.skillUrl)
                }
            }
        }
    }
}

@Composable
private fun TechStackItem(text: String, iconRes: DrawableResource, skillUrl: String) {
    val uriHandler = LocalUriHandler.current
    Box(
        modifier = Modifier
            .clip(RoundedCornerShape(8.dp))
            .background(Color.Black)
            .clickable {
                if (skillUrl.isNotEmpty()) {
                    uriHandler.openUri(skillUrl)
                }
            },
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier.padding(8.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            Image(
                modifier = Modifier.size(24.dp),
                painter = painterResource(iconRes),
                contentDescription = "Skill Icon"
            )
            Text(text = text, style = LocalCustomTypography.current.mini, color = Color.White)
        }
    }
}