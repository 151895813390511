package com.cmp.portfolio

import androidx.compose.ui.graphics.Color
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.agam_logo
import composemultiplatform.composeapp.generated.resources.app_usage_tracker_logo
import composemultiplatform.composeapp.generated.resources.foody_logo
import composemultiplatform.composeapp.generated.resources.freedom_logo
import composemultiplatform.composeapp.generated.resources.gps_locker_logo
import composemultiplatform.composeapp.generated.resources.ic_skill_3d_avatar
import composemultiplatform.composeapp.generated.resources.ic_skill_android_sdk
import composemultiplatform.composeapp.generated.resources.ic_skill_architecture
import composemultiplatform.composeapp.generated.resources.ic_skill_canbus
import composemultiplatform.composeapp.generated.resources.ic_skill_di
import composemultiplatform.composeapp.generated.resources.ic_skill_etoe_testing
import composemultiplatform.composeapp.generated.resources.ic_skill_file_system
import composemultiplatform.composeapp.generated.resources.ic_skill_firebase
import composemultiplatform.composeapp.generated.resources.ic_skill_git
import composemultiplatform.composeapp.generated.resources.ic_skill_github
import composemultiplatform.composeapp.generated.resources.ic_skill_gitlab
import composemultiplatform.composeapp.generated.resources.ic_skill_hmi
import composemultiplatform.composeapp.generated.resources.ic_skill_http
import composemultiplatform.composeapp.generated.resources.ic_skill_infotainment
import composemultiplatform.composeapp.generated.resources.ic_skill_integration_testing
import composemultiplatform.composeapp.generated.resources.ic_skill_java
import composemultiplatform.composeapp.generated.resources.ic_skill_jetpack_compose
import composemultiplatform.composeapp.generated.resources.ic_skill_multithread
import composemultiplatform.composeapp.generated.resources.ic_skill_mysql
import composemultiplatform.composeapp.generated.resources.ic_skill_phone
import composemultiplatform.composeapp.generated.resources.ic_skill_php
import composemultiplatform.composeapp.generated.resources.ic_skill_playstore
import composemultiplatform.composeapp.generated.resources.ic_skill_preference
import composemultiplatform.composeapp.generated.resources.ic_skill_restful_api
import composemultiplatform.composeapp.generated.resources.ic_skill_retrofit
import composemultiplatform.composeapp.generated.resources.ic_skill_room_db
import composemultiplatform.composeapp.generated.resources.ic_skill_ros
import composemultiplatform.composeapp.generated.resources.ic_skill_signed_apk
import composemultiplatform.composeapp.generated.resources.ic_skill_sqlite
import composemultiplatform.composeapp.generated.resources.ic_skill_tablet
import composemultiplatform.composeapp.generated.resources.ic_skill_unit_testing
import composemultiplatform.composeapp.generated.resources.ic_skill_watch
import composemultiplatform.composeapp.generated.resources.ic_skill_xml
import composemultiplatform.composeapp.generated.resources.ic_start_up_logo_place_holder
import composemultiplatform.composeapp.generated.resources.ic_tools_android_studio
import composemultiplatform.composeapp.generated.resources.ic_tools_figma
import composemultiplatform.composeapp.generated.resources.ic_tools_linear
import composemultiplatform.composeapp.generated.resources.ic_tools_notion
import composemultiplatform.composeapp.generated.resources.ic_tools_postman
import composemultiplatform.composeapp.generated.resources.ic_tools_slack
import composemultiplatform.composeapp.generated.resources.ic_tools_teams
import composemultiplatform.composeapp.generated.resources.ic_tools_xcode
import composemultiplatform.composeapp.generated.resources.intruder_capture_logo
import composemultiplatform.composeapp.generated.resources.multivariate_logo
import composemultiplatform.composeapp.generated.resources.my_city_events_logo
import composemultiplatform.composeapp.generated.resources.pomodoro_task_timer_logo
import composemultiplatform.composeapp.generated.resources.project_agam
import composemultiplatform.composeapp.generated.resources.project_app_usage_tracker
import composemultiplatform.composeapp.generated.resources.project_foody
import composemultiplatform.composeapp.generated.resources.project_freedom
import composemultiplatform.composeapp.generated.resources.project_gps_locker
import composemultiplatform.composeapp.generated.resources.project_img_intruder_capture
import composemultiplatform.composeapp.generated.resources.project_img_notebook_watch
import composemultiplatform.composeapp.generated.resources.project_pomodoro_task_timer
import composemultiplatform.composeapp.generated.resources.skill_icon_kotlin
import composemultiplatform.composeapp.generated.resources.university_of_waterloo_logo
import composemultiplatform.composeapp.generated.resources.vcet_logo
import composemultiplatform.composeapp.generated.resources.zoho_logo
import composemultiplatform.composeapp.generated.resources.zoho_notebook_logo
import org.jetbrains.compose.resources.DrawableResource

data class ProjectPreviewData(
    val imageResId: DrawableResource,
    val title: String,
    val category: String,
    val userCount: String,
    val tag: Project,
    val link: String
)

data class ProjectDetailsData(
    val title: String,
    val description: String,
    val projectImageResId: DrawableResource,
    val tag: Project,
    val backgroundColor: Color,
    val link: String
)

data class SkillTitleWithIconData(
    val title: String,
    val iconResource: DrawableResource,
    val skillUrl: String = ""
)

data class SkillsData(
    val skillTitle: String,
    val skillSets: ArrayList<SkillTitleWithIconData>
)

data class ExperienceData(
    val title: String = "",
    val shortDescription: String = "",
    val description: String = "",
    val iconResource: DrawableResource = Res.drawable.freedom_logo,
    val duration: String = "",
    val companyUrl: String = "",
    val designation: String = "",
    val type: String = ""
)

data class AutomotiveExperienceData(
    val title: String = "",
    val description: String = ""
)

val projectPreviewItems = listOf(
    ProjectPreviewData(
        Res.drawable.freedom_logo,
        "Freedom: Site/App Blocker",
        "Productivity",
        "1M+",
        Project.FREEDOM,
        link = "https://play.google.com/store/apps/details?id=to.freedom.android2&hl=en_CA&gl=US"
    ),
    ProjectPreviewData(
        Res.drawable.intruder_capture_logo,
        "Catch Phone Intruder",
        "Security",
        "100K+",
        Project.INTRUDER_CAPTURE,
        link = "https://play.google.com/store/apps/details?id=com.foxbytecode.captureintruder&hl=en_CA&gl=US"
    ),
    ProjectPreviewData(
        Res.drawable.zoho_notebook_logo,
        "Notebook - Note-taking & To-do",
        "Productivity",
        "1M+",
        Project.NOTEBOOK,
        link = "https://play.google.com/store/apps/details?id=com.zoho.notebook&hl=en_CA&gl=US"
    ),
    ProjectPreviewData(
        Res.drawable.gps_locker_logo,
        "GPS Locker",
        "Tools",
        "10K+",
        Project.GPS_LOCKER,
        link = "https://play.google.com/store/apps/details?id=com.foxbytecode.gpslocker"
    ),
    ProjectPreviewData(
        Res.drawable.agam_logo,
        "Agam",
        "Education",
        "10K+",
        Project.AGAM,
        "https://play.google.com/store/apps/details?id=com.reddishapple.Agam"
    ),
    ProjectPreviewData(
        Res.drawable.foody_logo,
        "Foody",
        "Food & Drink",
        "Not Published Yet",
        Project.FOODY,
        "https://drive.google.com/file/d/1mW8nKSluhiIIC12tyj-_aaVaV2pd9u3T/view?usp=sharing"
    ),
    ProjectPreviewData(
        Res.drawable.app_usage_tracker_logo,
        "App Usage Tracker",
        "Digital Wellbeing",
        "500+",
        Project.APP_USAGE_TRACKER,
        link = "https://play.google.com/store/apps/details?id=com.app.use.tracker&hl=en_CA&gl=US"
    ),
    ProjectPreviewData(
        Res.drawable.pomodoro_task_timer_logo,
        "Pomodoro Task Timer",
        "Productivity",
        "10+",
        Project.POMODORO_TASK_TIMER,
        link = "https://apps.apple.com/in/app/pomodoro-task-timer/id1583909765"
    )
)

//        Color(0xFFFFE0B2),
//        Color(0xFFB3E5FC),
//        Color(0xFFC8E6C9),
//        Color(0xFFF8BBD0),
//        Color(0xFFD1C4E9),
//        Color(0xFFFFF9C4),
//        Color(0xFFCFD8DC),
//        Color(0xFFFFF3E0),

val projectDetailItems = listOf(
    ProjectDetailsData(
        "FREEDOM",
        "Freedom is an app designed to help users manage distractions and improve productivity by blocking apps, websites, or notifications for set periods of time. It's particularly useful for limiting access to social media, games, or other apps that might be distracting during work or study sessions. Users can schedule sessions where specific apps or websites are blocked, helping them stay focused on tasks without interruptions. Freedom also includes features like reading tech blogs, streaming focus sounds, and setting up recurring schedules. It supports all major platforms, making it a popular choice among students and professionals looking to boost their productivity by minimizing distractions.",
        Res.drawable.project_freedom,
        Project.FREEDOM,
        Color(0xFFB8E4C6),
        link = "https://play.google.com/store/apps/details?id=to.freedom.android2&hl=en_CA&gl=US"
    ),
    ProjectDetailsData(
        "INTRUDER CAPTURE",
        "Intruder Capture helps users identify and detect unauthorized attempts to access their phone by taking a selfie of anyone who tries to unlock the device without consent. The app securely saves these images in a private, password-protected gallery, ensuring that only the user can view or delete them. Additionally, the app can be configured to send the images via email for added security, and they do not appear in the device's main gallery. Intruder Capture supports capturing selfies in various scenarios and features custom days memory management, which automatically deletes captured images from the device after a user-defined number of days.",
        Res.drawable.project_img_intruder_capture,
        Project.INTRUDER_CAPTURE,
        Color(0xFFFFE0B3),
        link = "https://play.google.com/store/apps/details?id=com.foxbytecode.captureintruder&hl=en_CA&gl=US"
    ),
    ProjectDetailsData(
        "ZOHO NOTEBOOK",
        "Zoho Notebook is a versatile note-taking and to-do app available for iOS, Android, and Mac devices. It offers seamless synchronization across platforms, allowing users to create and organize notes, set reminders, and maintain a digital journal. With support for various note types—text, checklists, audio recordings, photos, files, sketches, and smart notes—Zoho Notebook ensures that different types of content are managed effectively. Notably, the app extends its functionality to Android Wear OS, enabling users to capture and manage text notes, checklists, audio recordings, photos, and smart notes directly from their watch. Whether you’re jotting down ideas, managing tasks, or accessing your content from any device.",
        Res.drawable.project_img_notebook_watch,
        Project.NOTEBOOK,
        Color(0xFFB3E5FC),
        link = "https://play.google.com/store/apps/details?id=com.zoho.notebook&hl=en_CA&gl=US"
    ),
    ProjectDetailsData(
        "GPS LOCKER",
        "GPS Locker allows users to lock their location coordinates at a constant place or set a new location as their current position. It enables users to spoof their location by selecting a mock position, which can be set as the default location finder app. The app also maintains a history of previously chosen locations, allowing users to easily reuse and lock these locations from their location history. This functionality helps users manage their location data effectively.",
        Res.drawable.project_gps_locker,
        Project.GPS_LOCKER,
        Color(0xFFFFE0B3),
        link = "https://play.google.com/store/apps/details?id=com.foxbytecode.gpslocker"
    ),
    ProjectDetailsData(
        "AGAM",
        "The Agam App is designed to facilitate collaboration and information sharing among medical students and lecturers. It allows users to share medical books and lecture notes, upload and download educational materials, and access event information such as blood donation camps and medical exhibitions through its event module. The app features a news feed where students can share their thoughts, comment, and like posts related to medicine. Agam fosters a community beyond the classroom, aiming to connect and inspire future physicians. It provides a student-friendly platform for learning, interaction, and enrichment, supporting the development of a robust healthcare network.",
        Res.drawable.project_agam,
        Project.AGAM,
        Color(0xFFFFFFFF),
        "https://play.google.com/store/apps/details?id=com.reddishapple.Agam"
    ),
    ProjectDetailsData(
        "FOODY",
        "Foody connects users with home-cooked meal options through its dual modules: Chef and User. Chefs can register by uploading their food safety certificate, enabling them to offer cooking services for events like parties and birthdays. Users can book a chef for their events, place food orders, and receive notifications from nearby chefs. Chefs provide quotes detailing preparation time and cost, which users can review and accept. Once accepted, the chef prepares and delivers the meal to the user’s doorstep or for takeout. The app also features a rating and review system for users to assess chefs on food quality, taste, cost, and preparation time. Foody is one of the apps I developed from scratch, including Figma design, app development, PHP backend, and SQL database, ensuring a comprehensive and seamless experience.",
        Res.drawable.project_foody,
        Project.FOODY,
        Color(0xFFFFF3E0),
        link = "https://drive.google.com/file/d/1mW8nKSluhiIIC12tyj-_aaVaV2pd9u3T/view?usp=sharing"
    ),
    ProjectDetailsData(
        "APP USAGE TRACKER",
        "App Usage Tracker is an Android app designed to monitor and analyze your app usage habits. It offers detailed statistics and usage reports to help you understand and manage your phone habits more effectively. The app tracks usage data on an hourly, daily, and weekly basis, covering metrics such as screen time, unlock count, app open count, and notification count. All statistics are derived from Android EventStats, categorized by event type. For better visualization, the app includes various charts that make it easier to interpret your usage patterns. Users can also export their data in CSV format for further analysis. With App Usage Tracker, you can gain valuable insights into your app usage and make informed decisions to enhance productivity and device management.",
        Res.drawable.project_app_usage_tracker,
        Project.APP_USAGE_TRACKER,
        Color(0xFFD7C1F0),
        link = "https://play.google.com/store/apps/details?id=com.app.use.tracker&hl=en_CA&gl=US"
    ),
    ProjectDetailsData(
        "POMODORO TASK TIMER",
        "The Pomodoro Task Timer app helps users create, track, and complete tasks within designated times to enhance focus on work or study. Users can set up tasks with titles, descriptions, and deadlines. Once a task's completion time is reached, the app triggers a sound and notification to alert the user. The app features a task history section where users can review completed tasks. For development, we utilized UserDefaults for storing settings, Core Data for managing task details, Swift for functionality, and timers for tracking task completion. This project marked my first foray into iOS development, providing users with a practical tool to boost productivity and maintain focus.",
        Res.drawable.project_pomodoro_task_timer,
        Project.POMODORO_TASK_TIMER,
        Color(0xFFA4C8D8),
        link = "https://apps.apple.com/in/app/pomodoro-task-timer/id1583909765"
    )
)

val skillsItems = listOf(
    SkillsData(
        "Language", arrayListOf(
            SkillTitleWithIconData(
                "kotlin",
                Res.drawable.skill_icon_kotlin,
                "https://kotlinlang.org/"
            ),
            SkillTitleWithIconData("Java", Res.drawable.ic_skill_java, "https://www.java.com/en/"),
            SkillTitleWithIconData("Php", Res.drawable.ic_skill_php, "https://www.php.net/"),
            SkillTitleWithIconData("MySQL", Res.drawable.ic_skill_mysql, "https://www.mysql.com/")
        )
    ),
    SkillsData(
        "Version Control", arrayListOf(
            SkillTitleWithIconData("Git", Res.drawable.ic_skill_git, "https://git-scm.com/"),
            SkillTitleWithIconData(
                "GitLab",
                Res.drawable.ic_skill_gitlab,
                "https://about.gitlab.com/"
            ),
            SkillTitleWithIconData("GitHub", Res.drawable.ic_skill_github, "https://github.com/"),
        )
    ),
    SkillsData(
        "User Interface", arrayListOf(
            SkillTitleWithIconData(
                "XML",
                Res.drawable.ic_skill_xml,
                "https://en.wikipedia.org/wiki/XML"
            ),
            SkillTitleWithIconData(
                "Jetpack Compose",
                Res.drawable.ic_skill_jetpack_compose,
                "https://developer.android.com/develop/ui/compose"
            ),
        )
    ),
    SkillsData(
        "Dependency Injection", arrayListOf(
            SkillTitleWithIconData(
                "Hilt",
                Res.drawable.ic_skill_di,
                "https://developer.android.com/training/dependency-injection/hilt-android"
            ),
            SkillTitleWithIconData(
                "Dagger",
                Res.drawable.ic_skill_di,
                "https://developer.android.com/training/dependency-injection/dagger-android"
            ),
        )
    ),
    SkillsData(
        "Testing", arrayListOf(
            SkillTitleWithIconData(
                "Unit Testing",
                Res.drawable.ic_skill_unit_testing,
                "https://developer.android.com/training/testing/local-tests"
            ),
            SkillTitleWithIconData(
                "Integration Testing",
                Res.drawable.ic_skill_integration_testing,
                "https://developer.android.com/training/testing/fundamentals"
            ),
            SkillTitleWithIconData(
                "End To End Testing",
                Res.drawable.ic_skill_etoe_testing,
                "https://developer.android.com/training/testing/fundamentals"
            ),
        )
    ),
    SkillsData(
        "Automotive", arrayListOf(
            SkillTitleWithIconData(
                "Human-Machine Interface (HMI)",
                Res.drawable.ic_skill_hmi,
                "https://bambooapps.eu/blog/what-is-automotive-hmi"
            ),
            SkillTitleWithIconData(
                "Infotainment System",
                Res.drawable.ic_skill_infotainment,
                "https://en.wikipedia.org/wiki/In-car_entertainment"
            ),
            SkillTitleWithIconData(
                "ROS (Robot Operating System) Communication",
                Res.drawable.ic_skill_ros,
                "https://www.ros.org/"
            ),
            SkillTitleWithIconData(
                "CAN (Controller Area Network) Bus Protocol",
                Res.drawable.ic_skill_canbus,
                "https://en.wikipedia.org/wiki/CAN_bus#:~:text=A%20controller%20area%20network%20(CAN,electronic%20control%20units%20(ECUs)."
            ),
            SkillTitleWithIconData(
                "3D Vehicle Avatar",
                Res.drawable.ic_skill_3d_avatar,
                "https://upload.wikimedia.org/wikipedia/commons/1/13/Tesla_Model_3_interior.jpg"
            ),
        )
    ),
    SkillsData(
        "Architecture Patterns", arrayListOf(
            SkillTitleWithIconData(
                "MVI",
                Res.drawable.ic_skill_architecture,
                "https://medium.com/swlh/mvi-architecture-with-android-fcde123e3c4a"
            ),
            SkillTitleWithIconData(
                "MVVM",
                Res.drawable.ic_skill_architecture,
                "https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93viewmodel"
            ),
            SkillTitleWithIconData(
                "MVC",
                Res.drawable.ic_skill_architecture,
                "https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93controller"
            ),
        )
    ),
    SkillsData(
        "Common Services", arrayListOf(
            SkillTitleWithIconData(
                "Android SDK",
                Res.drawable.ic_skill_android_sdk,
                "https://en.wikipedia.org/wiki/Android_SDK"
            ),
            SkillTitleWithIconData(
                "Firebase",
                Res.drawable.ic_skill_firebase,
                "https://firebase.google.com/"
            ),
            SkillTitleWithIconData(
                "FCM",
                Res.drawable.ic_skill_firebase,
                "https://firebase.google.com/docs/cloud-messaging"
            ),
            SkillTitleWithIconData(
                "Crashlytics",
                Res.drawable.ic_skill_firebase,
                "https://firebase.google.com/docs/crashlytics"
            ),
            SkillTitleWithIconData(
                "Analytics",
                Res.drawable.ic_skill_firebase,
                "https://firebase.google.com/docs/analytics"
            ),
            SkillTitleWithIconData(
                "Authentication",
                Res.drawable.ic_skill_firebase,
                "https://firebase.google.com/docs/auth"
            ),
        )
    ),
    SkillsData(
        "Network", arrayListOf(
            SkillTitleWithIconData(
                "Restful API",
                Res.drawable.ic_skill_restful_api,
                "https://www.techtarget.com/searchapparchitecture/definition/RESTful-API#:~:text=A%20RESTful%20API%20is%20an,deleting%20operations%20related%20to%20resources."
            ),
            SkillTitleWithIconData(
                "Retrofit",
                Res.drawable.ic_skill_retrofit,
                "https://square.github.io/retrofit/"
            ),
            SkillTitleWithIconData(
                "OkHttp",
                Res.drawable.ic_skill_http,
                "https://square.github.io/okhttp/"
            ),
        )
    ),
    SkillsData(
        "Responsive Design", arrayListOf(
            SkillTitleWithIconData(
                "Android phones",
                Res.drawable.ic_skill_phone,
                "https://en.wikipedia.org/wiki/Android_(operating_system)"
            ),
            SkillTitleWithIconData(
                "Android tablets",
                Res.drawable.ic_skill_tablet,
                "https://en.wikipedia.org/wiki/Android_(operating_system)"
            ),
            SkillTitleWithIconData(
                "Android watches (Wear OS)",
                Res.drawable.ic_skill_watch,
                "https://wearos.google.com/"
            ),
        )
    ),
    SkillsData(
        "Distribution", arrayListOf(
            SkillTitleWithIconData(
                "Signed APK",
                Res.drawable.ic_skill_signed_apk,
                "https://developer.android.com/studio/publish/app-signing"
            ),
            SkillTitleWithIconData(
                "Google Playstore",
                Res.drawable.ic_skill_playstore,
                "https://play.google.com/store/apps?hl=en"
            ),
        )
    ),
    SkillsData(
        "Asynchronous", arrayListOf(
            SkillTitleWithIconData(
                "Threads",
                Res.drawable.ic_skill_multithread,
                "https://developer.android.com/topic/performance/threads"
            ),
            SkillTitleWithIconData(
                "Coroutines",
                Res.drawable.skill_icon_kotlin,
                "https://kotlinlang.org/docs/coroutines-overview.html"
            ),
        )
    ),
    SkillsData(
        "Storage", arrayListOf(
            SkillTitleWithIconData(
                "Shared Preferences/DataStore",
                Res.drawable.ic_skill_preference,
                "https://developer.android.com/topic/libraries/architecture/datastore"
            ),
            SkillTitleWithIconData(
                "Room Database",
                Res.drawable.ic_skill_room_db,
                "https://developer.android.com/training/data-storage/room"
            ),
            SkillTitleWithIconData(
                "SQLite Database",
                Res.drawable.ic_skill_sqlite,
                "https://developer.android.com/training/data-storage/sqlite"
            ),
            SkillTitleWithIconData(
                "File Systems",
                Res.drawable.ic_skill_file_system,
                "https://developer.android.com/reference/kotlin/java/nio/file/FileSystem"
            ),
        )
    ),
    SkillsData(
        "Multiplatform - In progress", arrayListOf(
            SkillTitleWithIconData(
                "Kotlin Multiplatform",
                Res.drawable.skill_icon_kotlin,
                "https://kotlinlang.org/docs/multiplatform.html"
            ),
            SkillTitleWithIconData(
                "Compose Multiplatform",
                Res.drawable.ic_skill_jetpack_compose,
                "https://www.jetbrains.com/lp/compose-multiplatform/"
            )
        )
    ),
    SkillsData(
        "Tools", arrayListOf(
            SkillTitleWithIconData(
                "Android Studio",
                Res.drawable.ic_tools_android_studio,
                "https://developer.android.com/studio/"
            ),
            SkillTitleWithIconData(
                "Figma",
                Res.drawable.ic_tools_figma,
                "https://www.figma.com/"
            ),
            SkillTitleWithIconData(
                "Linear",
                Res.drawable.ic_tools_linear,
                "https://linear.app/"
            ),
            SkillTitleWithIconData(
                "Notion",
                Res.drawable.ic_tools_notion,
                "https://www.notion.so/"
            ),
            SkillTitleWithIconData(
                "Postman",
                Res.drawable.ic_tools_postman,
                "https://www.postman.com/"
            ),
            SkillTitleWithIconData(
                "Slack",
                Res.drawable.ic_tools_slack,
                "https://slack.com/"
            ),
            SkillTitleWithIconData(
                "Teams",
                Res.drawable.ic_tools_teams,
                "https://www.microsoft.com/en-ca/microsoft-teams/group-chat-software"
            ),
            SkillTitleWithIconData(
                "Xcode",
                Res.drawable.ic_tools_xcode,
                "https://developer.apple.com/xcode/"
            )
        )
    )
)

val experienceItems = listOf(
    ExperienceData(
        title = "Freedom",
        description = "At Freedom, I am responsible for driving the Android app's evolution by integrating new features, fixing bugs, and modernizing the codebase. My work involves migrating the app from XML to Jetpack Compose and adopting new architectural patterns to enhance performance and maintainability. I collaborate closely with my Android team and cross-functional teams, using tools like Linear for project management, Slack for communication, and Git for version control, ensuring smooth pull requests and seamless merge commits.",
        iconResource = Res.drawable.freedom_logo,
        duration = "APR 2024 - PRESENT",
        companyUrl = "https://freedom.to/",
        designation = "Android Developer",
        type = "Part-Time Contractor"
    ),
    ExperienceData(
        title = "University Of Waterloo",
        description = "To refine my software development expertise, I recently completed a Master’s degree in Electrical and Computer Engineering at the University of Waterloo, specializing in Software Engineering. This program deepened my knowledge and skills, preparing me to tackle complex software challenges and drive innovative solutions.",
        iconResource = Res.drawable.university_of_waterloo_logo,
        duration = "MAY 2023 - AUG 2024",
        companyUrl = "https://www.uwaterloo.ca/",
        designation = "Graduate Student",
        type = "Full-Time"
    ),
    ExperienceData(
        title = "Startup Company",
        description = "I designed and built a cutting-edge infotainment system app from the ground up. This innovative application integrates a pre-trained hand gesture recognition model, enabling users to control the system entirely through gestures detected by the infotainment system’s camera. By leveraging the latest advancements in Android development—such as Jetpack Compose, Kotlin, MVVM, and Hilt—I created a touchless user experience that enhances convenience and safety. The app supports gesture-based control for maps, radio, calls, contacts, vehicle settings, and weather modules. Additionally, I updated their existing SDK sample Android app to the new architecture while maintaining rigorous version control using Git.",
        iconResource = Res.drawable.ic_start_up_logo_place_holder,
        duration = "AUG 2023 - JAN 2024",
        designation = "Lead HMI Infotainment System Developer",
        type = "Part-Time Contractor"
    ),
    ExperienceData(
        title = "Zoho Corporation",
        description = "I developed the Zoho Notebook Watch app (Wear OS) from scratch, ensuring it included the key features of the Android mobile app while enabling seamless synchronization between the watch and mobile versions. This project was built using Jetpack Compose, Room DB, MVVM, Kotlin, RESTful API, Hilt, etc.\n" +
                "\n" +
                "As a standalone developer, I spearheaded the development of a Human-Machine Interface (HMI) infotainment system from the ground up for an EV truck. This involved integrating 3D GLB models provided by the vehicle manufacturer, creating dynamic user interactions, and establishing communication protocols using CAN-BUS and ROS. I also integrated a reverse camera system and developed custom features like a speedometer, vehicle charging information display, and media player to deliver a comprehensive and interactive user experience.\n" +
                "\n" +
                "Additionally, I contributed to the Zoho Notebook Android mobile app by migrating it from older architectures and XML to a modern framework with Jetpack Compose. My work involved feature enhancements and bug fixes, significantly improving the app's performance and usability.",
        iconResource = Res.drawable.zoho_logo,
        duration = "JAN 2022 - MAR 2023",
        companyUrl = "https://www.zohocorp.com/",
        designation = "Android Developer & Lead HMI Infotainment System Developer",
        type = "Full-Time"
    ),
    ExperienceData(
        title = "Multivariate AI Pvt",
        description = "I developed and published 5+ Android apps, now live on the Play Store with over 100k+ active users. I led a team of two developers, ensuring the delivery of high-quality, stable applications. My expertise includes Android SDK, XML for UI design, MVC architecture, and Room for efficient data management. I’m proficient in Git for version control and CI/CD pipelines, consistently delivering projects on time.",
        iconResource = Res.drawable.multivariate_logo,
        duration = "SEP 2020 - JAN 2022",
        companyUrl = "https://www.multivariate.tech/",
        designation = "Android Developer",
        type = "Full-Time"
    ),
    ExperienceData(
        title = "My City Events",
        description = "During my internship as an Android developer, I gained hands-on experience by building an event organizing app from scratch. This involved transforming an existing website into a fully functional Android application, leveraging WebViews for seamless integration and user interaction.",
        iconResource = Res.drawable.my_city_events_logo,
        duration = "JAN 2020 - MAR 2020",
        companyUrl = "https://in.linkedin.com/company/my-city-events",
        designation = "Android Developer Intern",
        type = "Intern"
    ),
    ExperienceData(
        title = "Vcet",
        description = "I earned a Bachelor of Technology degree in Information Technology with first-class honors and distinction, highlighting my strong foundation and exceptional performance in software engineering.",
        iconResource = Res.drawable.vcet_logo,
        duration = "AUG 2016 - MAR 2020",
        companyUrl = "https://www.annauniv.edu/",
        designation = "Undergraduate Student",
        type = "Full-Time"
    )
)

//val bigExperienceItems = listOf(
//    ExperienceData(
//        title = "Freedom",
//        description = "As an Android application developer, I have taken on diverse responsibilities, including developing a standalone project from the ground up. Additionally, I have successfully expanded an existing Android app to seamlessly integrate with Android Wear OS, enhancing its functionality and user experience. This role has allowed me to leverage cutting-edge Android technologies and best practices, ensuring the delivery of high-quality, user-centric applications that meet both client requirements and end-user needs.",
//        iconResource = Res.drawable.freedom_logo,
//        duration = "APR 2024 - PRESENT",
//        companyUrl = "https://freedom.to/"
//    ),
//    ExperienceData(
//        title = "University Of Waterloo",
//        description = "Pursuing a Master's degree in Engineering at the University of Waterloo, focusing on advanced topics in software engineering and mobile application development. This program has provided me with in-depth knowledge of emerging technologies, research methodologies, and innovative approaches to solving complex engineering problems. Through rigorous coursework and hands-on projects, I have honed my skills in areas such as machine learning, data analytics, and advanced mobile computing, preparing me for cutting-edge roles in the tech industry.",
//        iconResource = Res.drawable.university_of_waterloo_logo,
//        duration = "MAY 2023 - AUG 2024",
//        companyUrl = "https://www.uwaterloo.ca/"
//    ),
//    ExperienceData(
//        title = "Startup Contractor",
//        description = "As an Android application developer, I am working on a cutting-edge Android application tailored for a car infotainment (HMI) system. This innovative project focuses on developing an application capable of discerning hand positions through the infotainment system's camera, enabling user interaction exclusively through hand gestures. By leveraging an AI/ML-powered hand gesture SDK, we've eliminated the need for touch-based interactions, enhancing safety and user experience in automotive environments. The development process utilizes the latest advancements in Android technology, including Jetpack Compose, Kotlin, MVVM architecture, and Hilt for dependency injection, ensuring a modern, efficient, and maintainable codebase.",
//        iconResource = Res.drawable.ic_start_up_logo_place_holder,
//        duration = "AUG 2023 - JAN 2024"
//    ),
//    ExperienceData(
//        title = "Zoho Corporation",
//        description = "In my role as an Android application developer at Zoho Corporation, I took on diverse and challenging projects that significantly expanded my skill set. I successfully developed a standalone project from scratch, demonstrating my ability to architect and implement complex mobile applications. Additionally, I played a key role in extending an existing Android app to seamlessly integrate with Android Wear OS, showcasing my adaptability and expertise in wearable technology. These projects allowed me to work with cutting-edge Android technologies, implement robust design patterns, and optimize performance for various device form factors.",
//        iconResource = Res.drawable.zoho_logo,
//        duration = "JAN 2022 - MAR 2023",
//        companyUrl = "https://www.zohocorp.com/"
//    ),
//    ExperienceData(
//        title = "Multivariate AI Pvt",
//        description = "During my tenure at Multivariate AI Pvt, I made significant contributions as an Android application developer and team leader. I spearheaded the development of approximately 10 standalone Android applications, all of which are currently live on the Play Store and boast a consistent user base exceeding 150,000. These projects encompassed a wide range of functionalities and industries, allowing me to gain diverse experience in app development, user engagement strategies, and performance optimization. As a development team leader, I successfully managed a team of three developers, overseeing project timelines, conducting code reviews, and mentoring junior developers to ensure high-quality deliverables and foster professional growth within the team.",
//        iconResource = Res.drawable.multivariate_logo,
//        duration = "SEP 2020 - JAN 2022",
//        companyUrl = "https://www.multivariate.tech/"
//    ),
//    ExperienceData(
//        title = "My City Events",
//        description = "During my internship as an Android developer at My City Events, I gained invaluable hands-on experience in mobile application development. I was entrusted with the task of building an Android application from the ground up, which provided me with a comprehensive understanding of the entire development lifecycle. The project heavily utilized webviews, allowing me to gain expertise in integrating web content seamlessly into native Android applications. This experience not only enhanced my technical skills but also provided insights into user experience design, performance optimization, and the challenges of developing hybrid applications.",
//        iconResource = Res.drawable.my_city_events_logo,
//        duration = "JAN 2020 - MAR 2020",
//        companyUrl = "https://in.linkedin.com/company/my-city-events"
//    ),
//    ExperienceData(
//        title = "Vcet",
//        description = "I completed my Bachelor of Technology degree in Information Technology with first-class honors and distinction at Vcet. This comprehensive program provided me with a strong foundation in computer science, software engineering, and information systems. Throughout my academic journey, I engaged in various challenging projects, participated in coding competitions, and developed a keen interest in mobile application development. The rigorous curriculum, combined with practical assignments and industry-oriented coursework, prepared me well for the demands of the rapidly evolving tech industry.",
//        iconResource = Res.drawable.vcet_logo,
//        duration = "AUG 2016 - MAR 2020",
//        companyUrl = "https://www.annauniv.edu/"
//    )
//)

val automotiveExperienceItems = listOf(
    AutomotiveExperienceData(
        title = "Automotive Infotainment System Application",
        description = "As a standalone developer, I spearheaded the development of Human-Machine Interface (HMI) infotainment system applications for both an EV truck and a car, delivering a comprehensive and interactive user experience from the ground up. This project, developed at Zoho Corporation, encompassed several critical components, including a 3D Vehicle Avatar, CAN-BUS Protocol integration, ROS Communication System, Navigation Maps Layout, and a Media Player. I handled both the software and hardware wiring between the HMI and OBD to mock the communication from the vehicle with actual pre-recorded vehicle logs during the development of the infotainment system."
    ),
    AutomotiveExperienceData(
        title = "3D Vehicle Avatar",
        description = "I implemented a 3D vehicle avatar that enhances user interaction within the infotainment system. This involved handling 3D GLB models provided by the vehicle manufacturer through NX CAD software. The avatar was optimized for low and medium HMI hardware configurations and offers dynamic interaction, such as turning on vehicle lights when clicked on the avatar, or displaying door open animations in sync with the actual vehicle status. The avatar also adjusts its movement and angle based on different driving modes, providing an immersive experience."
    ),
    AutomotiveExperienceData(
        title = "Reverse Camera Integration",
        description = "I integrated the physical reverse camera with the HMI, connecting it with the infotainment system app. This setup displays the camera preview from the vehicle alongside the 3D vehicle avatar when the user switches to reverse driving mode."
    ),
    AutomotiveExperienceData(
        title = "Vehicle Speedometer and Charging Information",
        description = "I developed a custom speedometer and vehicle charging information display using Canvas, which presents real-time vehicle speed and charging metrics in various units, such as kilowatts, miles, and kilometers."
    ),
    AutomotiveExperienceData(
        title = "Communication Protocols",
        description = "To facilitate communication between the HMI and the vehicle, I implemented both wired and wireless communication protocols using CAN-BUS and ROS. This involved encoding and decoding byte data into human-readable information, allowing the HMI to control various autonomous features and vehicle systems."
    ),
    AutomotiveExperienceData(
        title = "Navigation Layout",
        description = "I created a navigation layout that supports the vehicle's autonomous features, integrating in-built maps with a path-drawing system. This system dynamically rescales real-time location coordinates into canvas offset values, enabling the display of the vehicle's driven path in real-time."
    ),
    AutomotiveExperienceData(
        title = "Media Player",
        description = "The media player I developed enables users to load songs from local HMI storage, with features like play, pause, forward, backward, shuffle, search, and volume control."
    ),
    AutomotiveExperienceData(
        title = "Gesture-Controlled Infotainment System",
        description = "In a separate project for another startup, I developed an infotainment system app that integrates a pre-trained hand gesture recognition model. This model allows users to interact with the infotainment system without touching the HMI, enabling gesture-based control of maps, radio, calls, contacts, vehicle settings, and weather modules."
    )
)

enum class SECTION(val displayName: String) {
    ABOUT("About"),
    TECH_STACK("Tech Stack"),
    PROJECTS("Projects"),
    AUTOMOTIVE_PROJECTS("Automotive Projects"),
    EXPERIENCE("Experience"),
    CONTACT("Contact"),
}

const val copyRights = "© 2024 Kishore Kumarasamy. All rights reserved."
const val titleAndroidDev = "Android Application Developer"
const val titleInfotainmentDev = "Infotainment System Developer"
const val subtitle =
    "Passionate about transforming ideas into scalable, cutting-edge Android applications."
const val contactMeDescription =
    "Got a new project? Reach out to me today and let’s get started. I’m always eager to explore new opportunities and collaborate on exciting ventures. Connect with me on LinkedIn or send me an email. Let's create something amazing together!"
const val contactMeLocation = "Waterloo, Ontario, Canada (Open to relocation if needed)"
const val note = "Note: Since Compose Multiplatform is still evolving, you might notice some adaptive screen size issues. Thank you for visiting and for your understanding!"
const val shortNote = "Made with Compose Multiplatform and love."