package com.cmp.portfolio.newDesktop

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.MultiLineRow
import com.cmp.portfolio.SkillsData
import com.cmp.portfolio.skillsItems
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDeskTopTechStackScreen() {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(20.dp)
    ) {
        LazyColumn(Modifier.fillMaxWidth(), verticalArrangement = Arrangement.spacedBy(10.dp))
        {
            items(skillsItems) { skillsData ->
                TechStackSet(skillsData)
            }
        }
    }
}

@Composable
private fun TechStackSet(skillData: SkillsData) {
    Column {
        Text(skillData.skillTitle, style = LocalCustomTypography.current.miniSemiBold)
        MultiLineRow {
            skillData.skillSets.forEach { skill ->
                Box(modifier = Modifier.padding(5.dp))
                {
                    TechStackItem(skill.title, skill.iconResource, skill.skillUrl)
                }
            }
        }
    }
}

@Composable
private fun TechStackItem(text: String, iconRes: DrawableResource, skillUrl: String) {
    val uriHandler = LocalUriHandler.current
    Box(
        modifier = Modifier
            .clip(RoundedCornerShape(8.dp))
            .background(Color.Black)
            .clickable {
                if (skillUrl.isNotEmpty()) {
                    uriHandler.openUri(skillUrl)
                }
            },
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier.padding(8.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            Image(
                modifier = Modifier.size(24.dp),
                painter = painterResource(iconRes),
                contentDescription = "Skill Icon"
            )
            Text(text = text, style = LocalCustomTypography.current.mini, color = Color.White)
        }
    }
}