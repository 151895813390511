package com.cmp.portfolio.mobile

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.ExperienceData
import com.cmp.portfolio.PortfolioConstants
import com.cmp.portfolio.experienceItems
import com.cmp.portfolio.sp
import org.jetbrains.compose.resources.painterResource

@Composable
fun ExperienceScreen() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 10.dp, bottom = 10.dp, end = 10.dp)
            .border(
                width = 2.dp,
                color = Color.White,
                shape = RoundedCornerShape(
                    topStart = 0.dp,
                    topEnd = 10.dp,
                    bottomStart = 10.dp,
                    bottomEnd = 10.dp
                )
            )
    )
    {
        Column(modifier = Modifier.padding(horizontal = 10.dp)) {
            experienceItems.forEach {
                ExperienceItem(it)
            }
        }
    }
}

@Composable
fun ExperienceItem(experienceData: ExperienceData) {
    val uriHandler = LocalUriHandler.current
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 10.dp),
        elevation = CardDefaults.cardElevation(10.dp),
        colors = CardColors(
            containerColor = Color.White,
            contentColor = Color.Black,
            disabledContentColor = Color.Transparent,
            disabledContainerColor = Color.Transparent
        ),
        shape = RoundedCornerShape(
            topStart = 10.dp,
            topEnd = 10.dp,
            bottomStart = 10.dp,
            bottomEnd = 10.dp
        )
    )
    {
        val density = LocalDensity.current
        var rowHeight by remember { mutableStateOf(0.dp) }
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(10.dp)
                .onGloballyPositioned { coordinates ->
                    rowHeight = with(density) { coordinates.size.height.toDp() }
                },
            horizontalArrangement = Arrangement.spacedBy(10.dp)
        ) {
            val duration = experienceData.duration.split(" - ")
            Column(
                verticalArrangement = Arrangement.SpaceBetween,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Column(
                    verticalArrangement = Arrangement.spacedBy(10.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Image(
                        modifier = Modifier.size(48.dp).clickable(
                            interactionSource = MutableInteractionSource(),
                            indication = null
                        ) {
                            uriHandler.openUri(experienceData.companyUrl)
                        },
                        painter = painterResource(experienceData.iconResource),
                        contentDescription = "Company Icon"
                    )
                    if (duration.size == 2) {
                        Text(
                            "${duration[0]}\n${duration[1]}",
                            fontSize = 8.sp
                        )
                    } else {
                        Text("ERROR", fontSize = 8.sp)
                    }
                }
                val jobType = experienceData.type.split(" ")
                Text(
                    text = if (jobType.size > 1) (jobType[0] + "\n" + jobType[1]) else jobType[0],
                    fontSize = 8.sp
                )
            }
            VerticalDivider(
                modifier = Modifier
                    .width(2.dp)
                    .height(rowHeight), color = Color.Black
            )
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                Text(modifier = Modifier.clickable(
                    interactionSource = MutableInteractionSource(),
                    indication = null
                ) {
                    uriHandler.openUri(experienceData.companyUrl)
                },
                    text = experienceData.title,
                    style = LocalCustomTypography.current.smallSemiBold
                )
                Text(experienceData.description, style = LocalCustomTypography.current.small)
            }
        }
    }
}