package com.cmp.portfolio.mobile

import LocalCustomTypography
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.Project
import com.cmp.portfolio.projectDetailItems
import com.cmp.portfolio.projectPreviewItems
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.available_on_app_store
import composemultiplatform.composeapp.generated.resources.available_on_playstore
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun ProjectScreen() {
    val currentProjectPreview = remember { mutableStateOf(Project.FREEDOM) }
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 10.dp),
        shape = RoundedCornerShape(
            topStart = 0.dp,
            topEnd = 10.dp,
            bottomEnd = 10.dp,
            bottomStart = 10.dp
        ),
        colors = CardColors(
            containerColor = Color.White,
            contentColor = Color.Black,
            Color.Transparent,
            Color.Transparent
        )
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(10.dp)
        ) {
            projectPreviewItems.forEach { projectPreviewData ->
                val isExpanded = projectPreviewData.tag == currentProjectPreview.value
                Column {
                    ProjectItem(
                        projectPreviewData.imageResId,
                        projectPreviewData.title,
                        projectPreviewData.category,
                        projectPreviewData.userCount,
                        isExpanded = isExpanded,
                        onItemClicked = {
                            currentProjectPreview.value =
                                if (currentProjectPreview.value == projectPreviewData.tag) {
                                    Project.DEFAULT
                                } else {
                                    projectPreviewData.tag
                                }
                        }
                    )
                    AnimatedVisibility(isExpanded) {
                        val projectDetailsData =
                            projectDetailItems[projectPreviewItems.indexOf(projectPreviewData)]
                        ProjectDetailsItem(
                            tag = projectDetailsData.tag,
                            link = projectDetailsData.link,
                            description = projectDetailsData.description
                        )
                    }
                    HorizontalDivider(
                        color = Color.Black.copy(0.09f)
                    )
                }
            }

        }
    }
}

@Composable
private fun ProjectItem(
    logo: DrawableResource,
    title: String,
    description: String,
    userCount: String,
    isExpanded: Boolean,
    onItemClicked: () -> Unit
) {
    val logoResourceId = remember { logo }
    val logoPainter = painterResource(logoResourceId)
    val rotationAngle by animateFloatAsState(if (isExpanded) 180f else 0f, label = "Icon Rotation")
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 20.dp, end = 20.dp, top = 10.dp, bottom = 10.dp)
            .clickable(
                indication = null,
                interactionSource = remember { MutableInteractionSource() }
            ) { onItemClicked() },
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(10.dp)
        ) {
            Image(
                modifier = Modifier.size(60.dp),
                painter = logoPainter,
                contentDescription = "App logo"
            )
            Column(
                horizontalAlignment = Alignment.Start
            ) {
                Text(title, style = LocalCustomTypography.current.smallSemiBold)
                Text(description, style = LocalCustomTypography.current.small)
                Text(userCount, style = LocalCustomTypography.current.small)
            }
        }
        Icon(
            imageVector = Icons.Filled.ArrowDropDown,
            contentDescription = "Expand Arrow",
            modifier = Modifier
                .padding(end = 8.dp)
                .rotate(rotationAngle)
        )
    }
}

@Composable
private fun ProjectDetailsItem(
    tag: Project,
    link: String,
    description: String
) {
    val uriHandler = LocalUriHandler.current
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.Black.copy(0.09f)),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier.padding(vertical = 10.dp, horizontal = 20.dp),
            verticalArrangement = Arrangement.spacedBy(10.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                text = description,
                style = LocalCustomTypography.current.small
            )
            Image(
                modifier = Modifier.clickable { uriHandler.openUri(link) },
                painter = painterResource(if (tag == Project.POMODORO_TASK_TIMER) Res.drawable.available_on_app_store else Res.drawable.available_on_playstore),
                contentDescription = "Get it on playstore"
            )
        }
    }
}