package com.cmp.portfolio

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

enum class DeviceType {
    MOBILE,
    TABLET,
    DESKTOP;

    // Factory method that creates an instance of the class based on window width
    companion object {
        fun basedOnWidth(windowWidth: Dp): DeviceType {
            return when {
                windowWidth < 600.dp -> MOBILE
                windowWidth < 840.dp -> TABLET
                else -> DESKTOP
            }
        }
    }
}