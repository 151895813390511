package com.cmp.portfolio.mobile

import LocalCustomTypography
import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.ic_about_me_2
import composemultiplatform.composeapp.generated.resources.ic_download
import org.jetbrains.compose.resources.painterResource

@Composable
fun TopBar(
    onResumeDownloadClicked: () -> Unit,
    backgroundColor: Color
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(20.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Card(
            modifier = Modifier
                .border(
                    color = Color(0xFFFBFBF8),
                    shape = RoundedCornerShape(40.dp),
                    width = 2.dp
                )
                .clickable(interactionSource = remember {
                    MutableInteractionSource()
                }, indication = null) {
                },
            shape = RoundedCornerShape(40.dp),
            elevation = CardDefaults.cardElevation(10.dp),
            colors = CardColors(
                containerColor = backgroundColor,
                contentColor = Color.Black,
                Color.Transparent,
                Color.Transparent
            )

        ) {
            Row(
                modifier = Modifier.padding(horizontal = 15.dp, vertical = 10.dp),
                horizontalArrangement = Arrangement.spacedBy(5.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier.size(24.dp),
                    painter = painterResource(Res.drawable.ic_about_me_2),
                    contentDescription = "Menu Icon"
                )
                Text(text = "About Me", style = LocalCustomTypography.current.small)
            }
        }

        Card(
            modifier = Modifier
                .border(
                    color = Color(0xFFFBFBF8),
                    shape = RoundedCornerShape(40.dp),
                    width = 2.dp
                )
                .clickable(interactionSource = remember {
                    MutableInteractionSource()
                }, indication = null) {
                    onResumeDownloadClicked.invoke()
                },
            shape = RoundedCornerShape(40.dp),
            elevation = CardDefaults.cardElevation(10.dp),
            colors = CardColors(
                containerColor = backgroundColor,
                contentColor = Color.Black,
                Color.Transparent,
                Color.Transparent
            )

        ) {
            Row(
                modifier = Modifier.padding(horizontal = 15.dp, vertical = 10.dp),
                horizontalArrangement = Arrangement.spacedBy(5.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier.size(24.dp),
                    painter = painterResource(Res.drawable.ic_download),
                    contentDescription = "Menu Icon"
                )
                Text(text = "Resume", style = LocalCustomTypography.current.small)
            }
        }
    }
}
