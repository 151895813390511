package com.cmp.portfolio.newDesktop

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.IconButton
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowLeft
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.cmp.portfolio.Project
import com.cmp.portfolio.projectDetailItems
import com.cmp.portfolio.sp
import composemultiplatform.composeapp.generated.resources.Res
import composemultiplatform.composeapp.generated.resources.available_on_app_store
import composemultiplatform.composeapp.generated.resources.available_on_playstore
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun NewDesktopProjectDetailedScreen(
    currentProjectPreview: Project,
    onArrowClicked: (Project) -> Unit
) {
    val rowListState = rememberLazyListState()
    val coroutineScope = rememberCoroutineScope()
    LaunchedEffect(currentProjectPreview)
    {
        val index = projectDetailItems.indexOfFirst { it.tag == currentProjectPreview }
        if (index >= 0 && index != rowListState.firstVisibleItemIndex) {
            rowListState.animateScrollToItem(index)
        }
    }
    Box(
        Modifier.fillMaxWidth().clip(shape = RoundedCornerShape(10.dp))
    )
    {
        Row(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            LazyRow(
                modifier = Modifier
                    .fillMaxHeight()
                    .weight(1f), state = rowListState,
                userScrollEnabled = false
            ) {
                items(projectDetailItems) { projectDetailsData ->
                    ProjectDetailsItem(
                        title = projectDetailsData.title,
                        description = projectDetailsData.description,
                        link = projectDetailsData.link,
                        tag = projectDetailsData.tag,
                        projectImageResId = projectDetailsData.projectImageResId,
                        modifier = Modifier
                            .fillParentMaxSize()
                            .background(projectDetailsData.backgroundColor)
                            .padding(vertical = 50.dp, horizontal = 20.dp)
                    )
                }
            }
        }
        if (rowListState.firstVisibleItemIndex != 0) {
            IconButton(
                modifier = Modifier.align(Alignment.CenterStart)
                    .padding(start = 20.dp)
                    .background(
                        Color.Black,
                        shape = CircleShape
                    ), onClick = {
                    coroutineScope.launch {
                        val firstVisibleItemIndex = rowListState.firstVisibleItemIndex
                        if (firstVisibleItemIndex > 0) {
                            rowListState.animateScrollToItem(
                                firstVisibleItemIndex - 1
                            )
                            onArrowClicked(projectDetailItems[rowListState.firstVisibleItemIndex].tag)
                        }
                    }
                }) {
                Icon(
                    modifier = Modifier.padding(10.dp),
                    imageVector = Icons.AutoMirrored.Filled.KeyboardArrowLeft,
                    tint = Color.White,
                    contentDescription = "Left Arrow"
                )
            }
        }
        if (rowListState.firstVisibleItemIndex != projectDetailItems.size - 1) {
            IconButton(
                modifier = Modifier.align(Alignment.CenterEnd)
                    .padding(end = 20.dp)
                    .background(
                        Color.Black,
                        shape = CircleShape,
                    ), onClick = {
                    coroutineScope.launch {
                        val firstVisibleItemIndex = rowListState.firstVisibleItemIndex
                        if (firstVisibleItemIndex < projectDetailItems.size - 1) {
                            rowListState.animateScrollToItem(
                                firstVisibleItemIndex + 1
                            )
                            onArrowClicked(projectDetailItems[rowListState.firstVisibleItemIndex].tag)
                        }
                    }
                }) {
                Icon(
                    modifier = Modifier.padding(10.dp),
                    imageVector = Icons.AutoMirrored.Filled.KeyboardArrowRight,
                    tint = Color.White,
                    contentDescription = "Right Arrow"
                )
            }
        }

    }
}

@Composable
private fun ProjectDetailsItem(
    title: String,
    description: String,
    link: String,
    tag: Project,
    projectImageResId: DrawableResource,
    modifier: Modifier
) {
    val localUriHandler = LocalUriHandler.current
    Box(
        modifier = modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    )
    {
        Row(
            modifier = Modifier.fillMaxSize().padding(horizontal = 50.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {

            LazyColumn(
                modifier = Modifier.weight(0.7f).padding(20.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(30.dp)
            ) {
                item {
                    Text(
                        title.uppercase(), style = TextStyle(
                            fontSize = 35.sp,
                            fontWeight = FontWeight.Bold
                        )
                    )
                }
                item {
                    Text(
                        description,
                        style = TextStyle(
                            fontSize = 15.sp
                        )
                    )
                }
                item {
                    Image(
                        modifier = Modifier.height(50.dp).width(100.dp).clickable(
                            interactionSource = MutableInteractionSource(),
                            indication = null
                        ) {
                            localUriHandler.openUri(link)
                        },
                        painter = painterResource(if (tag == Project.POMODORO_TASK_TIMER) Res.drawable.available_on_app_store else Res.drawable.available_on_playstore),
                        contentDescription = "Play store Icon"
                    )
                }
            }
            Image(
                modifier = Modifier.fillMaxHeight().weight(0.3f),
                painter = painterResource(projectImageResId),
                contentDescription = "Project Image"
            )

        }
    }
}